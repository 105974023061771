import { useEffect, useRef, useState } from "react";
import NoiseDetector from "../components/NoiseDetector";
//import useChildStatus from "./useChildStatus";
import __ from "../components/Translate";

import { useSettingsConfig } from "../hooks/useSettings";
import {BabyStatus, NoiseBabyStatus} from "../components/BabyStatus";

function useAudioClassifications(meterData = null) {
    /*
* - Derive from classifications
* - Define status: silent, awake, crying, (additional noises?)
*/
    const noiseDetector = useRef();
    const settingsConfig = useSettingsConfig();

    const { noiseDetectionEnabled } = settingsConfig;

    const [mlSwitch,setMlSwitch] = useState(false);
    const noMlClassifications = useRef(null);
    const mlClassifications = useRef(null);

    const activityLog = useRef([]);

    const [noMlBabyStatus, setNoMlBabyStatus] = useState(0);
    const [mlBabyStatus, setMlBabyStatus] = useState(0);

    const no_ml_enumBabyStatus = {
        silence: 0,
        "noise1": 1,
        "noise2": 2
      };

    const ml_enumBabyStatus = {
        sleeping: 0,
        "stopped crying": 1,
        crying: 2
    };

    const noMlHistory = useRef([]);
    const mlHistory = useRef([]);

    const currentNoMlStatusFloat = useRef(0);
    const currentMlStatusFloat = useRef(0);

    const shrinkFactor = 0.95;

    const includeSpeechEnabled = useRef(true)

    //const [stream, setStream] = useState();
    const streamRef = useRef();

    const average = (array) => array.reduce((a, b) => a + b) / array.length;

    const suspendStream = () => {
        console.log('suspendStream');
        if (noiseDetector && noiseDetector.current) {
            const nd: any = noiseDetector.current;
            nd.suspendDetecting();
        }
    }

    const resumeStream = () => {
        console.log('resumeStream');
        if (noiseDetector && noiseDetector.current) {
            const nd: any = noiseDetector.current;
            nd.resumeDetecting();
        }
    }
    function setStream(myStream) {
        streamRef.current = myStream
        // setNoiseDetector(new NoiseDetector(window,//new NoiseDetector(window.AudioContext || window.webkitAudioContext,

        // get stream at component mount time to distribute it to NoiseDetector and WebRTC
        // this is because of iOS bug

        console.log('current audiocontext', window.AudioContext || window.webkitAudioContext);
        var old_classification = null;
        const detector = new NoiseDetector(window.AudioContext || window.webkitAudioContext, myStream,
            ({no_ml_result, ml_result}) => {
                // console.log('classifications ', [no_ml_result,ml_result]);                //setClassifications(result.classifications);

                if (!ml_result.classifications || ml_result.classifications.size === 0) return;

                noMlClassifications.current = no_ml_result.classifications
                mlClassifications.current = ml_result.classifications

                const currentNoMlBabyStatus = updateBabyStatus(noMlClassifications.current, noMlHistory, currentNoMlStatusFloat, no_ml_enumBabyStatus, 'noiseDetection');
                const currentMlBabyStatus = updateBabyStatus(mlClassifications.current, mlHistory, currentMlStatusFloat,  ml_enumBabyStatus, 'cryDetection');

                setNoMlBabyStatus(currentNoMlBabyStatus)
                setMlBabyStatus(currentMlBabyStatus)

                // maintaining activity log and sending it to parent
                const best_classification = Object.assign( {}, ml_result.classifications[0]);
                //setBestClassification(best_classification)

                //setRevelantNoiseDetected(NoiseDetector.childN  oiseDetected(result, includeSpeechEnabled))

            }
            , (msg) => console.log(msg), null, meterData
        );
        noiseDetector.current = detector;

    }

    // calculate current

    // calculate mode of array
    function mode(arr) {
        var numMapping = {};
        var greatestFreq = 0;
        var mode;
        arr.forEach(function findMode(number) {
            numMapping[number] = (numMapping[number] || 0) + 1;

            if (greatestFreq < numMapping[number]) {
                greatestFreq = numMapping[number];
                mode = number;
            }
        });
        return +mode;
    }
    function getKeyByValue(object, value) {
        return Object.keys(object).find(key => object[key] === value);
      }

    // update babystatus if classificaton was updated
    //useEffect(() => {
    function updateBabyStatus(classifications, history, currentStatusFloat, enumBabyStatus, type) {

        if (!classifications) return;

        let myBabyStatusNum;
        var jpath = require('json-path')
        const result_filtered = classifications.filter(obs => obs.probability > 0.1);
        //const res = jpath.resolve(result_filtered, "#/classifications[0]/mid")
        const res = jpath.resolve(result_filtered, "[]/mid")
        const crying_ids = new Set(
            ["/m/0463cq4", // "Crying, sobbing"
            "/t/dd00002",  // "Baby cry, infant cry",
            "/m/07qz6j3", // "Whimper",
            "/m/03qc9zr" //screaming
            ])
        const awake_ids = new Set(
            [////"/m/09x0r", //speech
             "/m/0261r1", // babbling
             ////"/m/01j3sz", // laughter
             "/t/dd00001", // baby laughter
             "/m/07sq110", // belly laugh
             "/m/07r660_", // giggle
             "/m/0ytgt", // child speech
             ////"/m/02qldy", // narration monologue
             //"/m/0jbk", //"Animal",
             //"/m/068hy", // domestic animal
             //"/m/01yrx", // cat
             //"/m/01280g", // wild animal
             //"/m/09d5_" //owl
        ]);
        if (res.filter(value => crying_ids.has(value)).length > 0)
            { myBabyStatusNum = 2; }
        else if (res.filter(value => awake_ids.has(value)).length > 0) {
            myBabyStatusNum = 1;
        }
        else { myBabyStatusNum = 0; }

        // console.log(`${type} push status into history ${myBabyStatusNum}`);
        history.current.push(myBabyStatusNum) //enumBabyStatus[myBabyStatus])

        // keep last elements
        history.current=history.current.slice(-2);

        // console.log(`${type} currentStatusFloat.current before: ${currentStatusFloat.current}`)
        // console.log(`${type} history: ${history.current}`)
        // console.log(`${type} mode: ${mode(history.current)}`);
        // console.log(`${type} status shrinked with ${shrinkFactor}: ${shrinkFactor * currentStatusFloat.current}`);
        // console.log(`${type} average: ${average([mode(history.current), shrinkFactor*currentStatusFloat.current])}`);

        if (type === 'noiseDetection') {
            // currentStatusFloat.current = shrinkFactor;
            // currentStatusFloat.current = mode(history.current) / 2 + shrinkFactor*currentStatusFloat.current / 2;
            // currentStatusFloat.current = average([mode(history.current), shrinkFactor*currentStatusFloat.current]);
            if (myBabyStatusNum === 2) {
                currentStatusFloat.current = myBabyStatusNum;
            } else {
                currentStatusFloat.current = average([average(history.current), shrinkFactor*currentStatusFloat.current]);
            }
        } else {
            currentStatusFloat.current = Math.max(mode(history.current), shrinkFactor*currentStatusFloat.current);
        }

        // console.log(`${type} currentStatusFloat.current after: ${currentStatusFloat.current}`)

        //currentStatusFloat.current= Math.max(enumBabyStatus[myBabyStatus], shrinkFactor*currentStatusFloat.current)
        // console.log(currentStatusFloat.current);
        // console.log(Math.round(currentStatusFloat.current));
        // console.log(enumBabyStatus);

        let finalStatus = Math.round(currentStatusFloat.current);
        // const currentBabyStatus = getKeyByValue(enumBabyStatus, finalStatus);
        // console.log([`${type} new baby status float:`, currentStatusFloat.current]);

        //setBabyStatus(currentBabyStatus);
        // console.log([`${type} new baby status:`, currentBabyStatus]);
        return finalStatus;

    }//, [classifications])

    useEffect(() => {
        // create copy from activity log
        const my_activityLog =  Array.from(activityLog.current);
        // if previous item exists then set last_time
        if (my_activityLog.length > 0) {
            my_activityLog[my_activityLog.length - 1]["last_time"] = new Date(); //.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        }
        // create new entry
        const finalBabyStatus = new BabyStatus(noMlBabyStatus, mlBabyStatus);
        const activity_item = { start_time: new Date(), // .toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' }),
            //name: __(mlBabyStatus),
            name: finalBabyStatus.createStatusMessage(),
            probability: 1.0 }
        // add new item
        my_activityLog.push(activity_item);
        //setActivityLog(my_activityLog);
        activityLog.current = my_activityLog;
    }, [noMlBabyStatus, mlBabyStatus])

    // function processed on mount
    useEffect(() => {
        return () => {
            console.log('useAudioClassifications component unmount.');
            // remove timer
            if (noiseDetector && noiseDetector.current)
                noiseDetector.current.componentWillUnmount();
        }

    }, []);

    // console.log(`useAudioClassifcation ${noMlBabyStatus}/${mlBabyStatus}`);
    return {
        no_ml_classifications: noMlClassifications, ml_classifications: mlClassifications,
        activityLog,
        noMlBabyStatus,
        mlBabyStatus,
        suspendStream,
        resumeStream,
        streamRef,
        setStream,
        mlSwitch,setMlSwitch
    };

}


/*

const no_ml_enumBabyStatus = {
    silence: 0,
    "noise1": 1,
    "noise2": 2
};

const ml_enumBabyStatus = {
    sleeping: 0,
    "stopped crying": 1,
    crying: 2
};

*/

export default useAudioClassifications;


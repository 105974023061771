import React, {useCallback, useContext, useState} from 'react';
import {
    IonItemDivider, IonInput,
    IonModal,
    IonList,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCardContent,
    IonItem,
    IonIcon,
    IonLabel,
    IonButton,
    IonButtons,
    IonMenuButton,
    IonBackButton, NavContext
} from '@ionic/react';
import {pin, wifi, wine, warning, walk, star, ellipsisHorizontal} from 'ionicons/icons';

import './Home.css';
import { getParentUrl } from "../util/utils.js";
import FirebaseSignaling from "../components/FirebaseSignaling";
// import FirebaseSignaling from "../FirebaseSignaling";

import ReactGA from 'react-ga';
import firebase from "firebase";
import { useAuthRequired } from "../hooks/useAuth";

ReactGA.initialize('UA-188532611-1');
ReactGA.pageview(window.location.pathname + window.location.search);

const Tab1: React.FC = () => {
    const { navigate } = useContext(NavContext);

    const [showModal, setShowModal] = useState(true);
    const [room, setRoom] = useState();
    const [roomExists, setRoomExists] = useState(true);

    useAuthRequired();

    const redirect = () => {
        // navigate('/station/parent/'+room, 'back');

        const parentUrl = getParentUrl(room, true);
        console.log('navigate to ', parentUrl);
        navigate(parentUrl, 'back');
    };

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton text="Back" defaultHref="/" style={{ height: '50px'}} />
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <div style={{display: "none"}}>
                    <div id="firebaseui-auth-container"></div>
                    <div id="loader">Loading...</div>
                </div>
                {/*<h1>Connect to Baby Station</h1>*/}
                <IonCard>
                    <img src="../assets/image/babystation2.jpg" />

                    <IonCardHeader>
                        {/*<IonCardSubtitle>Card Subtitle</IonCardSubtitle>*/}
                        <IonCardTitle>Connect to Baby Station</IonCardTitle>
                    </IonCardHeader>

                    <IonCardContent>
                        <section style={{ marginBottom: "10px"}}>
                            <h3 style={{ textTransform: 'uppercase', fontSize: "x-small" }}>
                                Connection</h3>
                            <p style={{ fontWeight: "bold"}}>Searching for nearby devices...</p>
                            <p>If your baby station isn't shown, you can add it manually here.</p>
                            <p style={{ fontWeight: "bold"}}>Parent Connection Code: <a onClick={async () => {
                                setShowModal(true);
                            }}>Enter Code</a></p>
                        </section>
                    </IonCardContent>
                </IonCard>

                <IonModal isOpen={showModal}>
                    <IonHeader translucent>
                        <IonToolbar>
                            {/*<IonTitle>Modal Content</IonTitle>*/}
                            <IonButtons slot="end">
                                {/*<IonButton onClick={() => setShowModal(false)}>Close</IonButton>*/}
                                <IonButton onClick={() => navigate('/station/', 'back')}>Close</IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent>
                        <h1 style={{ margin: '20px' }}>Please enter the code that is displayed on the Baby Station.</h1>
                        <p style={{ margin: "30px" }}>
                            { !roomExists && <p style={{color: "red"}}>Room cannot be found. Please verify room code.</p> }
                            <IonList>
                                {/*<IonItemDivider>Please enter the digit code that is displayed on the Baby Station.</IonItemDivider>*/}
                                <IonItem>
                                    <IonLabel position="floating">Baby Station Code</IonLabel>
                                    <IonInput type="text" type="number" pattern="\d*" value={room} placeholder="Enter Code"
                                      onIonChange={(e) => {
                                        //setRoom(parseInt(e.detail.value!, 10));
                                        setRoom(e.detail.value);
                                    }}></IonInput>
                                </IonItem>
                                <IonButton onClick={async () => {
                                    setRoomExists(true);
                                    const roomExists = await FirebaseSignaling.singleton.roomExists(room);
                                    // const roomExists = true;
                                    if (roomExists) {
                                        redirect();
                                    } else {
                                        setRoomExists(false);
                                    }
                                }}>Connect</IonButton>
                            </IonList>
                        </p>
                        {/*<h1>Please enter the digit code that is displayed on the Baby Station.</h1>*/}
                    </IonContent>
                </IonModal>
            </IonContent>
        </IonPage>
    );
    /*  return (
        <IonPage>
          <IonHeader>
            <IonToolbar>
              <IonTitle>Tab 1</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent fullscreen>
            <IonHeader collapse="condense">
              <IonToolbar>
                <IonTitle size="large">Tab 1</IonTitle>
              </IonToolbar>
            </IonHeader>
            <ExploreContainer name="Tab 1 page" />
          </IonContent>
        </IonPage>
      );*/
};

export default Tab1;

import NoiseDetector from "./NoiseDetector";
import FirebaseSignaling from "./FirebaseSignaling";

class MessagingService {

  constructor(peerRef) {
    this.peerRef = peerRef;
    this.callbacks = [];
  }

  async _send(event, roomId, recipient) {
    try {
      var i;
      //if (this.peerRef && this.peerRef.current) {
      this.peerRef.current.send(JSON.stringify(event));
      //}
    } catch (e) {
      console.warn(['Unable to send data',e]);
      throw new Error('MessagingService.send: WebRTC connection not available');
    }
  }

  asyncWait (ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async send(event, roomId, recipient, retry = { times: 1, wait: 0 }, useSignalingServer = false) {
    let i;
    for (i = 0; i < retry.times; i++) {
      try {
        //if (this.peerRef.current) {
          await this._send(event, roomId, recipient);
        //} else {
        //  await FirebaseSignaling.singleton.signal(roomId, event);
        //}
        // exit function if send succesful
        return;
      } catch (e) {
        if (useSignalingServer) await FirebaseSignaling.singleton.signal(roomId, event);
        else {
          //const asyncWait = ms => new Promise(resolve => setTimeout(resolve, ms))
          await this.asyncWait(retry.wait)
        }
      }
    }
    // loop ended without success of sending
    console.warn('Unable to send data');
    throw new Error('MessagingService.send: WebRTC connection not available');
  }

  /*
  async send(event, roomId, recipient) {
    if (this.peerRef && this.peerRef.current) {
      try {
        this.peerRef.current.send(JSON.stringify(event));
      } catch (err) {
        console.error('Unable to send event via webrtc peer', err);
        //if (roomId) await FirebaseSignaling.singleton.signal(roomId, event);
      }
    }
    // not sending via firebase
    //else if (roomId) {
    //  await FirebaseSignaling.singleton.signal(roomId, event, recipient);
    //}
    else {
      console.warn('Unable to send data');
    }
  }
  */

  async listen(roomId, callback, recipient) {
    console.log('register callbacks');
    // avoid generation of lots of callbacks from same function
    if (!this.callbacks.some((e) => e.toString() === callback.toString())) {
      //if (!this.callbacks.includes(callback)) {
      this.callbacks.push(callback);
      await FirebaseSignaling.singleton.listen(roomId, callback, recipient);
      // issue what if peer changes... need a refresh peer listeners method...
    }
    this.registerWebRtcCallback(callback);
  }

  registerWebRtcCallback(callback) {
    if (this.peerRef && this.peerRef.current) {
      const peer = this.peerRef.current;
      peer.on('data', data => {
        // got a data channel message
        console.log('got a message from peer1: ' + JSON.stringify(data));
        callback(JSON.parse(data));
      });
    }
  }

  refreshPeerListener(peerRef) {
    this.peerRef = peerRef;
    console.log('refreshPeerListener');
    this.callbacks.forEach(callback => this.registerWebRtcCallback(callback));
  }
}

export default MessagingService;

import { Typography } from "@material-ui/core";
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from "@material-ui/lab";
import {
  IonList,
  IonItem, IonLabel, IonModal, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonContent
} from '@ionic/react';
import React, { useState } from "react";
import { v4 as uuidv4 } from 'uuid';


function BabyActivityLog2(props) {

  // console.log('baby activity ', props);
  const [showActivityModal, setShowActivityModal] = useState(false);

  function renderActivitiy() {
    return function (log) {
      return <div key={uuidv4()}>
        {getStartTime(log)}
        {/*"last_time" in log ? "-" + log.last_time : ""*/}: {log.name}
        {/*(Probability: {parseFloat(log.probability).toFixed(2)})*/}
      </div>
    };
  }

  function renderActivityTimeLine() {
    return function (log) {
      return <TimelineItem>
        <TimelineOppositeContent>
          <Typography color="textSecondary">{log.start_time}</Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Typography>{log.name} </Typography>
        </TimelineContent>
      </TimelineItem>
    }
  }
  function getStartTime(log) {
    return new Date(log.start_time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit'  });
  }

  const length = "maxLength" in props ? Math.min(props.maxLength, props.activityLog.length) : props.activityLog.length
  const shortActivityList = props.activityLog.slice(-3).reverse().map(renderActivitiy());//reverse().

  const activityList = props.activityLog.slice(0).reverse().map(renderActivityTimeLine()); //reverse().

  return (
    <IonList style={{ padding: "0px" }}>
      {shortActivityList.length === 0 && "No activities monitored"}
      {shortActivityList.length > 0 && shortActivityList}
      {props.activityLog.length > 2 &&
        <div key={uuidv4()}>
          <a onClick={() => setShowActivityModal(true)}>See past activities</a>
        </div>
      }

      <IonModal isOpen={showActivityModal} onDidDismiss={() => setShowActivityModal(false)}>
        <IonHeader translucent>
          <IonToolbar>
            <IonTitle>Activities</IonTitle>
            <IonButtons slot="end">
              <IonButton onclick={() => setShowActivityModal(false)}>Close</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          <IonList style={{ padding: "20px" }}>
            <React.Fragment>
              <Timeline align="alternate">
                {activityList}
              </Timeline>
            </React.Fragment>
          </IonList>
        </IonContent>
      </IonModal>

    </IonList>
  )
}
export default BabyActivityLog2;

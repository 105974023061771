import __ from "./Translate";

class BabyStatus {

    public readonly noiseBabyStatus: number;
    public readonly aiBabyStatus: number;

    constructor(aNoiseBabyStatus: number, anAiBabyStatus: number) {
        this.noiseBabyStatus = aNoiseBabyStatus;
        this.aiBabyStatus = anAiBabyStatus;
    }

    getNoiseStatusMessage() {
        return __(`noiseStatus.${this.getDerivedNoiseBabyStatus()}`);
    }

    getAiStatusMessage() {
        return __(`aiStatus.${this.aiBabyStatus}`)
    }

    createStatusMessage() {
        if (this.noiseBabyStatus >= NoiseBabyStatus.SILENCE) {
            return __(`noiseStatus.${this.getDerivedNoiseBabyStatus()}`) + ", AI: " + __(`aiStatus.${this.aiBabyStatus}`);
        } else {
            return __(`noiseStatus.${this.noiseBabyStatus}`);
        }
    }

    public getDerivedNoiseBabyStatus() {
        return this.aiBabyStatus == AiBabyStatus.BABY_IS_CRYING ? NoiseBabyStatus.NOISE2 : this.noiseBabyStatus;
    }

}

enum NoiseBabyStatus {
    SILENCE = 0,
    NOISE1 = 1,
    NOISE2 = 2
}

enum AiBabyStatus {
    NO_BABY_NOISE = 0,
    BABY_STOPPED_CRYING = 1,
    BABY_IS_CRYING = 2
}

export { BabyStatus, NoiseBabyStatus, AiBabyStatus };
// export default BabyStatus;

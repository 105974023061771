import React from "react";

export function BrowserPermissionStatus(props) {

    console.log(props.permissions);
    if (props.permissions && (!props.permissions.camera || !props.permissions.mic)) {
        return <>
            <div style={{
                fontWeight: "bold", fontSize: "large",
                boxShadow: "1px 1px lightgrey",
                borderStyle: "solid", borderWidth: "2px",
                // backgroundColor: "lightgray",
                backgroundColor: "orange",
                borderColor: "red",
                // backgroundColor: "gainsboro",
                padding: "5px", borderRadius: "10px", color: "black"
            }}>
                <p style={{fontWeight: "bold"}}>Camera and audio permission required! Please try to reload this page and grant permission in your browser settings.</p>
            </div>
        </>;
    }

    return <></>;

}

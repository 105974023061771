import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {
  IonApp,
  IonRouterOutlet
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import './pages/Home.css';

import BabyStation from './pages/BabyStation';
import ParentStation from './pages/ParentStation';
import ParentStationConnect from "./pages/ParentStationConnect";

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
//import Child from "./Child";
//import Parent from "./Parent";
import Settings from "./pages/Settings";
import Home from "./pages/Home";

import {SettingsProvider, useSettings} from './hooks/useSettings';
import Authentication from "./pages/Authentication";

const App: React.FC = () => (
    <SettingsProvider>
    <IonApp>
      <IonReactRouter>
{/*
        <IonTabs  onIonTabsWillChange={() => {
          return false;
        }}>
*/}
          <IonRouterOutlet>
            <Route path="/station/" component={Home} exact={true}>
              {/*<Route component={Tab1} />*/}

              {/*<Route path="/baby" component={BabyStation} />*/}
              {/*<Route path="baby" component={BabyStation} />*/}
              {/*<Route path="/station/baby" component={BabyStation} />*/}
              {/*<Route path="/parent" component={ParentStation} />*/}
              {/*<Route path="/parentConnect" component={ParentStationConnect} />*/}
            </Route>
            <Route path="/station/baby" component={BabyStation} />
            <Route path="/station/parent/:room?" component={ParentStation} />
            <Route path="/station/parentConnect" component={ParentStationConnect} />
            <Route path="/login" component={Authentication} />
            {/*<Route path="/" render={() => <Redirect to="/station/" />} exact={true} />*/}
            {/*<Route path="/station/" render={() => <Redirect to="/station" />} exact={true} />*/}
            <Route path="/" render={() =>
                { window.location.href = '/landing_page/index.html'; }
                // <Redirect to="/landing_page" />
            } exact={true} />
            {/*<Route path="/station" render={() => <Redirect to="/" />} exact={true} />*/}
            <Route path="/settings" component={Settings} />

{/*
            <Route path="/child">
              <Child />
            </Route>
            <Route path="/parent/:room?" component={Parent} />
*/}
          </IonRouterOutlet>

      </IonReactRouter>
    </IonApp>
  </SettingsProvider>
);

export default App;

import firebase from "firebase/app";
import 'firebase/messaging';   // for cloud messaging

import { useEffect, useState, useRef } from "react";
import WebPushStatus from './WebPushStatus';


function useWebPush(messagingService, parentId, roomId) {
    const messagingWebPush = firebase.messaging.isSupported() ? firebase.messaging() : null;
    //const [pushToken, setPushToken] = useState(null);
    const pushToken = useRef<String>();
    const [webPushStatus, setWebPushStatus] = useState<WebPushStatus>(messagingWebPush !== null ? WebPushStatus.INIT : WebPushStatus.UNAVAILABLE);

    useEffect(() => {
        console.log("webpush: Component mounted: initialize firebase web push notification");
        if (messagingWebPush) {
            //initFirebaseMessaging();

            requestPermissionWebPush();
            // Get registration token. Initially this makes a network call, once retrieved
            // subsequent calls to getToken will return from cache.
            messagingWebPush.getToken({ vapidKey: 'BG18_JfNj6IqIF8u1NP1rRGBbefCT76WylzbZ76vbFfViKOBBYL4aVxY7WS9m7FlSdRXqpIH9lv8MJ86E1Xg0JM' }).then((currentToken) => {
                if (currentToken) {
                    //setPushToken(currentToken);
                    pushToken.current = currentToken;
                    //sendTokenToServer(currentToken);

                    console.log(`Registration Token ${currentToken}`);
                } else {
                    // Show permission request.
                    console.log('No registration token available. Request permission to generate one.');
                    // Show permission UI.
                    //setTokenSentToServer(false);
                }
            }).catch((err) => {
                console.log('webpush: An error occurred while retrieving token. ', err);
                // showToken('Error retrieving registration token. ', err);
                //setTokenSentToServer(false);
            });
            // Handle incoming messages. Called when:
            // - a message is received while the app has focus
            // - the user clicks on an app notification created by a service worker
            //   `messaging.setBackgroundMessageHandler` handler.
            messagingWebPush.onMessage((payload) => {
                setWebPushStatus(WebPushStatus.ON);
                console.log('webpush: Message received. ', payload);
                // const notificationTitle2 = 'onBackgroundMessage Test';
                // const notificationOptions = {
                //     body: 'onMessage Test',
                //     icon: '/firebase-logo.png'
                // };
                // registration.showNotification(notificationTitle2, notificationOptions);
                // ...
            });

            // Subscribe the devices corresponding to the registration tokens to the
            // topic.
            /*
            admin.messaging().subscribeToTopic('BG18_JfNj6IqIF8u1NP1rRGBbefCT76WylzbZ76vbFfViKOBBYL4aVxY7WS9m7FlSdRXqpIH9lv8MJ86E1Xg0JM', "room")
              .then(function (response) {
                // See the MessagingTopicManagementResponse reference documentation
                // for the contents of response.
                console.log('Successfully subscribed to topic:', response);
              })
              .catch(function (error) {
                console.log('Error subscribing to topic:', error);
              });
              */
        } else {
            console.log("webpush: Firebase Messaging not supported by browser.")
            setWebPushStatus(WebPushStatus.UNAVAILABLE);
        }

        return () => {
            console.log('webpush: useWebPush unmount');

        }
    }, []);


    function requestPermissionWebPush() {
        console.log('webpush: Requesting web push permission...');
        // [START request_permission]
        Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
                console.log('webpush: Notification permission granted.');
                // TODO(developer): Retrieve a registration token for use with FCM.
                // [START_EXCLUDE]
                // In many cases once an app has been granted notification permission,
                // it should update its UI reflecting this.
                //resetUI();
                // [END_EXCLUDE]
            } else {
                console.log('webpush: Unable to get permission to notify.');
            }
        });
        // [END request_permission]
    }


    function showToken(currentToken) {
        // Show token in console and UI.
        //const tokenElement = document.querySelector('#token');
        //tokenElement.textContent = currentToken;
    }

    // Send the registration token your application server, so that it can:
    // - send messages back to this app
    // - subscribe/unsubscribe the token from topics
    async function sendTokenToServer(currentToken) {
        //if (!isTokenSentToServer()) {
        console.log('webpush: Sending token to server...');
        // Send the current token to your server.
        try {
            await messagingService.current.send({
                //type: "pushToken",
                type: "parentRegistration",
                parentId: parentId.current,
                pushToken: currentToken
            }, roomId, 'child', {times : 5, wait : 2000}, true );
            setWebPushStatus(WebPushStatus.ON);
        } catch (e) {
            console.log("webpush: Unable to send token to child")
        }
        /*
        setTokenSentToServer(true);

      } else {
        console.log('Token already sent to server so won\'t send it again ' +
          'unless it changes');
      }
    */
    }

    function resendTokenToServer() {
        if (pushToken.current) sendTokenToServer(pushToken.current);
    }
    return { messagingWebPush, resendTokenToServer, webPushStatus }
}
/*
  function isTokenSentToServer() {
    return window.localStorage.getItem('sentToServer') === '1';
  }

  function setTokenSentToServer(sent) {
    window.localStorage.setItem('sentToServer', sent ? '1' : '0');
  }
*/

export default useWebPush;

//import firebase from 'firebase';
import firebase from 'firebase/app';
import 'firebase/firestore';   // for cloud firestore

// if (process.env.NODE_ENV !== 'development') {
if (window.location.hostname === 'nicenice.baby') {
  console.log = () => {}
  console.warn = () => {}
}

class FirebaseSignaling {

  // db: firebase.firestore.Firestore;
  // roomRef: awaited DocumentReference<T>;
//s  roomRef;

  constructor() {
    // this.init();
    // this.observeAuth();

  }

  init = () => {
    firebase.initializeApp({
      apiKey: 'AIzaSyAc8W32MiTDmPFX0oBqHt7AHnWJDYuqmKw',
      authDomain: 'webrtc-56950.firebaseapp.com',
      databaseURL: 'https://webrtc-56950.firebaseio.com',
      projectId: 'webrtc-56950',
      storageBucket: 'webrtc-56950.appspot.com',
      messagingSenderId: '642466922237',
      appId: '1:642466922237:web:58c21c39aca5d392fe0894',
      measurementId: 'G-9ZC4LQ51H0',
    });
    //firebase.analytics();
    this.db = firebase.firestore();

    this.registered = false;
  };



/*
  initRoom = async () => {
    this.roomRef = await this.db.collection('rooms').doc();
    // a first message is needed to actually create a room.
    this.roomRef.set({message: 'Room Created'});
  };
*/

  roomExists = async (roomId) => {
    if (!roomId) return false;

    // return this.db.collection('rooms').doc(roomId);
    const usersRef = this.db.collection('rooms').doc(roomId);
    return usersRef.get().then((docSnapshot) => {
        return docSnapshot.exists;
    });
  }


  createRoom = async (roomId, callback) => {
    const roomUserRef = await this.getRoom(roomId);
    // const roomUserRef = await collection.doc();
    // a first message is needed to actually create a room.
    await roomUserRef.set({message: `Room Created ${roomId}`});
    console.log(`Room Created: ${this.getRoomName(roomId)}`);

/*
    roomUserRef.onSnapshot(async snapshot => {
      console.log('Incoming room snapshot');
      const data = snapshot.data();
      if (data) {
        console.log('Got remote description: ', data);
        // const rtcSessionDescription = new RTCSessionDescription(data.answer);
        // await peerConnection.setRemoteDescription(rtcSessionDescription);
      }
    });
*/
    // if (!this.registered) {
      roomUserRef.collection('child').onSnapshot(async snapshot => {
        console.log('Incoming room snapshot ', snapshot);
        snapshot.docChanges().forEach(change => {
          console.log('Incoming room change ', change);
          if (change.type === 'added') {
            let data = change.doc.data();
            console.log(`Got data: ${JSON.stringify(data)}`);

            if (data.signal && data.signal.type && data.signal.type === 'offer') {
              console.log(`Got offer`);
              callback('somecaller', data.signal, roomId);
            }
          }
          // change.doc.delete();
        });
        // const data = snapshot.data();
      });
    //   this.registered = true;
    // }
  };

  asyncWait (ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async signal(roomId, data, subcollection = null) {
    console.log(`Send data to RoomID ${roomId} and subcollection ${subcollection}`, data);
    if (subcollection) {
      const roomRef = (await this.getRoom(roomId)).collection(subcollection).doc();
      await roomRef.set(data);
    } else {
      const roomRef = await this.getRoom(roomId);
      await roomRef.set(data);
    }
    // create a small wait step to avoid message racing...
    await this.asyncWait(500);
  }

  async sendOffer(roomId, data, callback) {
        // listen for answers
    await this.deleteCollection(roomId, 'parent');
    const collection = (await this.getRoom(roomId)).collection('parent');

    if (!this.registered) {
      collection.onSnapshot(async snapshot => {
        console.log('Incoming room snapshot ', snapshot);
        snapshot.docChanges().forEach(change => {
          console.log('Incoming room change ', change);
          if (change.type === 'added') {
            let data = change.doc.data();
            console.log(`Got data: ${JSON.stringify(data)}`);

            //if (data.type && data.type === 'answer') {
              if (data.type && (data.type === 'answer' || data.type === 'renegotiate')) {
              console.log(`Got answer}`);
              callback(data);
            }
          }
        });
      });
      this.registered = true;
    }

    await this.deleteCollection(roomId, 'child');
    await this.signal(roomId, data, 'child');
  }

  async listen(roomId, callback, subcollection = 'default') {
    const collection = (await this.getRoom(roomId)).collection(subcollection);

    collection.onSnapshot(async snapshot => {
      console.log('listening room snapshot ', snapshot);
      snapshot.docChanges().forEach(change => {
        console.log('listening  room change ', change);
        if (change.type === 'added') {
          let data = change.doc.data();
          console.log(`listening  Got data: ${JSON.stringify(data)}`);
          callback(data);
          collection.doc(change.doc.id).delete();
          // change.delete();
        }
      });
    });
  }

  createConnectionToken = async (roomId) => {
    console.log('Create join token');
    // const token = customAlphabet('1234567890', 6)();
    const token = Math.random().toString().slice(2,8);
    const roomRef = await this.getRoom(roomId);
    // await roomUserRef.set({message: `Room Created ${roomId}`});
    // const roomRef = (await this.getRoom(roomId)).collection('token').doc();
    const roomDoc = await roomRef.get();
    if (!roomDoc.data() || !roomDoc.data().token || roomDoc.data().token === null) {
      await roomRef.set({ token });
    }
    return token;
  }

  verifyToken = async (roomId, token) => {
    const roomRef = await this.getRoom(roomId);
    const roomDoc = await roomRef.get();
    if (roomDoc.data() && roomDoc.data().token === token) {
      // await roomRef.set({ token: null});
      return true;
    }
    return false;
  }

  deleteRoom = async (userid, subcollection = null) => {
    console.log('Delete room ', userid);
    try {
      await this.db.collection("rooms").doc(userid).delete();
      if (subcollection === null || subcollection === 'parent') {
        await this.deleteCollection(userid, "parent");
      }
      if (subcollection === null || subcollection === 'child') {
        await this.deleteCollection(userid, "child");
      }
    } catch (error) {
      console.log('Unable to delete room document ', error);
    }
  };

  getRoom = async (userid) => {
    return this.db.collection('rooms').doc(userid);
    // return this.db.collection('rooms').doc(userid).collection(this.getRoomName(userid)).doc();
  };

  getRoomName(userid) {
    const roomname = `rooms_${userid}`;
    console.log('Roomname ', roomname);
    return `rooms_${userid}`;
  }

  getRoomRef = () => {
    return this.roomRef;
  };

  addCallerCandidate = candidate => {
    // Code for collecting ICE candidates below
    const callerCandidatesCollection = this.roomRef.collection('callerCandidates');
    callerCandidatesCollection.add(candidate.toJSON()).then(r => console.log('caller candidate added'));
  };

  getCalleeCandidates = () => {
    return this.roomRef.collection('calleeCandidates');
  };

/*
  async sendOffer(offer) {
    console.log(`New room created with SDP offer. Room ID: ${this.roomRef.id}`);

    const roomWithOffer = {
      'offer': {
        type: offer.type,
        sdp: offer.sdp,
      },
    };
    await this.roomRef.set(roomWithOffer);
  }
*/

  async deleteCollection(roomId, subcollection) {
    const collectionRef = (await this.getRoom(roomId)).collection(subcollection);
    const query = collectionRef.orderBy('__name__').limit(100);

    return new Promise((resolve, reject) => {
      this.deleteQueryBatch(query, resolve).catch(reject);
    });
  }

  async deleteQueryBatch(query, resolve) {
    const snapshot = await query.get();

    const batchSize = snapshot.size;
    // console.log('batchsize ', batchSize)
    if (batchSize === 0) {
      // When there are no documents left, we are done
      resolve();
      return;
    }

    // Delete documents in a batch
    const batch = this.db.batch();
    snapshot.docs.forEach((doc) => {
      batch.delete(doc.ref);
    });
    await batch.commit();

    // Recurse on the next process tick, to avoid
    // exploding the stack.
    process.nextTick(() => {
      this.deleteQueryBatch(query, resolve);
    });
  }

}

if (!FirebaseSignaling.singleton) {
  console.log('Initialize firebase singleton');
  FirebaseSignaling.singleton = new FirebaseSignaling();
  FirebaseSignaling.singleton.init();
}
/*
FirebaseSignaling.singleton
  // .initRoom()
  .then(r =>
    console.log(`successfully created new room ${FirebaseSignaling.singleton.getRoomRef().id}`),
  );
*/
export default FirebaseSignaling;

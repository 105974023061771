import FirebaseSignaling from "../components/FirebaseSignaling";

export function getParentUrl(roomId, relative = false, token = null) {
  // return `http://localhost:3000/station/parent/${roomId}`;
  const prefix = relative ? '' : `${window.location.protocol}//${window.location.host}`;
  const url = `${prefix}/station/parent/${roomId}`;
  return token ? `${url}?token=${token}` : url;
}

export async function createParentUrlWithToken(roomId, relative = false) {
  // return `http://localhost:3000/station/parent/${roomId}`;
  const token = await FirebaseSignaling.singleton.createConnectionToken(roomId);
  // const token = "test"
  // await FirebaseSignaling.singleton.verifyToken(props.roomId, token);
  const prefix = relative ? '' : `${window.location.protocol}//${window.location.host}`;
  const url = `${prefix}/station/parent/${roomId}`;
  return token ? `${url}?token=${token}` : url;
}


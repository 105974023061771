import { useCallback, useEffect, useRef, useState } from "react";
import FirebaseSignaling from "../components/FirebaseSignaling";
import Peer from "simple-peer";
import MessagingService from "../components/MessagingService";
import useAudioClassifications from "./useAudioClassifications";
import usePeerDeviceStatus from "./usePeerDeviceStatus";
import { customAlphabet } from "nanoid";
import { createParentUrlWithToken, getParentUrl } from "../util/utils";
import __ from '../components/Translate'

import ReactGA from "react-ga";
import { BabyStatus } from "../components/BabyStatus";
import {Permissions, PermissionType} from "@capacitor/core";
import {useSettingsConfig} from "./useSettings";
ReactGA.initialize('UA-188532611-1');

function useChildPeer(showSnackBarMessage, settings, userVideo, partnerVideo, settingsConfig = null, startMeter = null, meterData = null) {

  // console.log('configure child peer ', settingsConfig);

  const {
    noiseThreshold, setNoiseThreshold,
    noiseDetectionEnabled, setNoiseDetectionEnabled,
    classificationEnabled, setClassificationEnabled,
    includeSpeechEnabled: config, setIncludeSpeechEnabled: setConfig,
    developmentMode, setDevelopmentMode
  } = useSettingsConfig();


  const constraintsUserMedia = {
/*    audio: {
      sampleRate: 24000,
      channelCount: 1,
      echoCancellation: false
      // volume: 1.0
      // sampleRate: 48000,
      // channelCount: 2,
      // volume: 1.0
    },*/
    audio: true,
    video: {
      /*
      width: { ideal: 1280 },
      height: { ideal: 720 },
      */
      facingMode: "environment"
    }
  };

  const [roomId, setRoomId] = useState("");
  const [shareToken, setShareToken] = useState(null);

  const myPeer = useRef();
  const messagingService = useRef(new MessagingService(myPeer));

  const [callerSignal, setCallerSignal] = useState();
  const [callAccepted, setCallAccepted] = useState(false);

  const [permissions, setPermissions] = useState(null);

  //const noSleep = new NoSleep();

  const { peerDeviceStatus, peerOnlineEventReceived, peerOfflineEventReceived, peerDeviceOfflineTimestamp } = usePeerDeviceStatus(myPeer);

  const pushToken = useRef();
  const pushTokens = useRef([]);
  const parentIDs = useRef([]);

  /**
   * Indicator that connection is established
   * - timestamp last firebase message
   * - webrtc connection is established and working
   * - todo: pings or always esteablished data webrtc connection)
   * - todo: define status: closed, connecting, connected
   * - todo: check if it can be checked if firebase message has been consumed
   * - todo: timer job that checks status regularly
   */
  const [connectionStatus, setConnectionStatus] = useState(false);

  const { no_ml_classifications, ml_classifications,
    activityLog,
    noMlBabyStatus, mlBabyStatus,
    suspendStream,
    resumeStream,
    streamRef,
    setStream,
    mlSwitch,setMlSwitch
  } = useAudioClassifications(meterData);

  useEffect(() => {
    if (startMeter) startMeter(streamRef.current);
  },[streamRef.current])

  useEffect(() => {
    console.log('settingsConfig.noiseDetectionEnabled');

    if (settingsConfig) {
      // this.setState({ ...this.state, [event.target.name]: event.target.checked });
      // setActiveButtonNoiseDetection(event.target.checked);
      if (settingsConfig.noiseDetectionEnabled) {
        //this.webRTCFacade.resumeWebRTC();
        resumeStream();
        //noSleep.enable();
      } else {
        suspendStream();
        //noSleep.disable();
        //this.webRTCFacade.suspendWebRTC();
      }
    }

  }, [settingsConfig.noiseDetectionEnabled, streamRef.current]);


  function sendClassifications(classifications) {
    const message = {
      type: "classifications",
      classifications
    };

    /*
    messagingService.current.send(message, roomId, 'parent').catch((e) => {
      console.log(e)
    })
    */

  }
  // on update of audio classifcation send them to parent
  /*
  useEffect(() => {
    sendClassifications(classifications);
  }, [classifications]);
  */

  function sendBabyStatus(no_ml_babyStatus, ml_babyStatus) {
    console.log(`sendBabyStatus ${no_ml_babyStatus}/${ml_babyStatus}`);

    const message = {
      type: "baby_status",
      no_ml_babyStatus: no_ml_babyStatus,
      ml_babyStatus: ml_babyStatus
    };

    /*
        // uncomment to test push notification
        try {
          console.log('webpush: sendParentNotification');
          sendParentNotification(message);
        } catch (e) {
          console.log('webpush: sendParentNotification failed', e);
        }
    */

    // sending current classification to parents
      messagingService.current.send(message, roomId, 'parent').catch((e) => {
        if (config.alertMode === 'noise' || (config.alertMode === 'crying' && ml_babyStatus >= 1)) {
          console.log("webpush: Not possible to send babystatus via webrtc using web push api");
          message.no_ml_babyStatus = 'Detected: ' + __(`noiseStatus.${message.no_ml_babyStatus}`);
          message.ml_babyStatus = __(`aiStatus.${message.ml_babyStatus}`);
          sendParentNotification(message);
          ReactGA.event({
            category: 'Baby Station',
            action: 'Communication',
            label: 'Send Baby Status',
            nonInteraction: true
          });
        }
      });
    }

  // on update of audio classifcation send them to parent
  useEffect(() => {
    sendBabyStatus(noMlBabyStatus, mlBabyStatus);
  }, [noMlBabyStatus, mlBabyStatus]);

  function sendActivityLog(activityLog) {
    console.log('sending activity log to parent');
    const message = {
      type: "activity_log",
      activity_log: activityLog.slice(-50)
    };

    messagingService.current.send(message, roomId, 'parent').catch((e) => {
      console.log(e)
    })
    ReactGA.event({
      category: 'Baby Station',
      action: 'Communication',
      label: 'Send Activity Log',
      nonInteraction: true
    });
  }

  // on update of audio activity log send them to parent
  useEffect(() => {
    sendActivityLog(activityLog.current);
  }, [noMlBabyStatus, mlBabyStatus]); //[activityLog]);

// if a relevant noise was detected send a notification to parent
  function sendParentNotification(message) {
    if (getPushToken() && roomId) {
      console.log(`webpush: sendParentNotification: using token: ${getPushToken()} Room: ${roomId}`);
      ReactGA.event({
        category: 'Baby Station',
        action: 'Communication',
        label: 'Send Push Notification',
        nonInteraction: true
      });
      const url = "https://fcm.googleapis.com/fcm/send";
      const options = {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        //mode: 'cors', // no-cors, *cors, same-origin
        //cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        //credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'key=AAAAlZYDPv0:APA91bGx2MkQeK2PiTdHrSiXIY6NCpRn7DZXuys7uqtKFYoSRTX8KCUqVUQLvbuBYFJ_GA_pCpJ4YFuVq7NX6aF33ihfNR9x21HHdkEtxUJZfAtxc09xMEy-2BIoE0DdhmEPFLLml7nl'
          //{ 'key': 'AAAAlZYDPv0:APA91bGx2MkQeK2PiTdHrSiXIY6NCpRn7DZXuys7uqtKFYoSRTX8KCUqVUQLvbuBYFJ_GA_pCpJ4YFuVq7NX6aF33ihfNR9x21HHdkEtxUJZfAtxc09xMEy-2BIoE0DdhmEPFLLml7nl' }
        },
        //redirect: 'follow', // manual, *follow, error
        //referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify({
          "to": getPushToken(),
          "priority": "high",
          "data":
          {
            "title": "NiceNice.Baby Alert",
            "body": message,
            "room": roomId,
            "tag": "childNoise",
            "parent_url": getParentUrl(roomId, false, shareToken)
          },
        }) // body data type must match "Content-Type" header
      }
      fetch(url, options)
        .then(res => res.json())
        .then(data => console.log(data))
        .catch((error) => {
          console.error('webpush: Error sending web push message:', error);
          ReactGA.event({
            category: 'Baby Station',
            action: 'Communication',
            label: `Push Notification Error`,
            nonInteraction: true
          });
        });
    } else {
      console.log(`webpush: sendParentNotification: web push message could not be sent. Token: ${(getPushToken())} Room: ${roomId}`);
    }
  }

  // TODO: When a webrtc connection is available than the webrtc channel would be sufficient...
  /*
  useEffect(() => {
    if (!revelantNoiseDetected) return;

    //curl --header "Content-Type: application/json" --header "Authorization: key=AAAAlZYDPv0:APA91bGx2MkQeK2PiTdHrSiXIY6NCpRn7DZXuys7uqtKFYoSRTX8KCUqVUQLvbuBYFJ_GA_pCpJ4YFuVq7NX6aF33ihfNR9x21HHdkEtxUJZfAtxc09xMEy-2BIoE0DdhmEPFLLml7nl" https://fcm.googleapis.com/fcm/send -d '{"notification": {"title": "The Title","body": "Hellof!", "sound": "default"},"priority": "high","to": "f9aMtlfOQ6d42YkiRv0Vsd:APA91bHrSVVwit4EsGZUtC1vslQB9eaf4qh631Tm_UXveyxM8rxp44lGDYs5Ry7WLgqYDxkljbrGvLAdmoonvvDwZq-GjmCz7lnfsL5nPW_nHaKNcYtAXSeA-NYjh881zz5UkT7199a3"}'
    const messageBody = getTimeFromDate(Date.now()) + ": " +
      bestClassification.name + " with " +
      bestClassification.probability.toFixed(2) + " confidence.";
    //sendParentNotification(messageBody);
  }, [revelantNoiseDetected]);
  */

  useEffect(async () => {
    console.log("Child js:componentDidMount");

    // get stream at component mount time to distribute it to NoiseDetector and WebRTC
    // this is because of iOS bug
    try {
      console.log('supported constraints: ', navigator.mediaDevices.getSupportedConstraints());
      const myStream = await navigator.mediaDevices.getUserMedia(constraintsUserMedia);
      console.log('audio tracks settings', myStream.getAudioTracks()[0].getSettings());
      setStream(myStream)
      subscribeRoom();
    } catch (e) {
      console.log('Permission issue', e);
      try {
        await checkPermissions();
      } catch (e2) {
        setPermissions({ camera: false, mic: false});
      }
    }


    return () => {
      console.log('component unmount');
      FirebaseSignaling.singleton.deleteRoom(roomId);
    }
  }, []);

  function setPushToken(jsonData) {
    pushToken.current = jsonData.pushToken;
    localStorage.setItem('pushToken.current', pushToken.current);
  }

  function getPushToken() {
    return pushToken.current || localStorage.getItem('pushToken.current');
  }

  function subscribeRoom() {
    ReactGA.event({
      category: 'Baby Station',
      action: 'Communication',
      label: 'Subscribe Room',
      nonInteraction: true
    });
    createRoom().then((roomId) => {
      messagingService.current.listen(roomId, async (event) => {
        console.log(`webpush: got a message from messagingService: ${JSON.stringify(event)}`);
        // console.log('got a message from peer1: ' + data);
        // const jsonData  = JSON.parse(event)
        const jsonData = event;
        // settings
        if (jsonData.type === "settings") {
          // updateSettings(jsonData.settings);
          if (settings && settings.current) {
            console.log('updateSettings');
            settings.current.updateSettings(jsonData.settings);
          }
        }
        // deal with a registration with a new parent
        // pushtoken
        if (jsonData.type === "parentRegistration") {
          console.log(["webpush: Received parentRegistration: ", jsonData])
          //setPushToken(jsonData.pushToken);
          setPushToken(jsonData);
          pushTokens.current.push(jsonData.pushToken)
          parentIDs.current.push = jsonData.parentId;
        }

      }, 'child');
    })
  }


  async function getStoredRoomId() {
    if (window.location.search.toLowerCase().includes('newroom')) {
      return null;
    }

    const storedRoomId = localStorage.getItem('yourID');
    console.log(`Local Storage RoomId ${storedRoomId}`);
    const roomExists = await FirebaseSignaling.singleton.roomExists(storedRoomId);
    return roomExists ? storedRoomId : null;
  }

  async function createRoom() {
    const storedRoomId = await getStoredRoomId();

    if (storedRoomId) {
      console.log(`restore firebase userid from localstorage `, storedRoomId);
      setRoomId(storedRoomId);
      await FirebaseSignaling.singleton.deleteRoom(storedRoomId);
      await FirebaseSignaling.singleton.createRoom(storedRoomId, acceptCall);
      return storedRoomId;
    } else {
      // const roomId = username.generateUsername('-');
      const roomId = customAlphabet('1234567890', 6)();
      localStorage.setItem('yourID', roomId);
      console.log(`firebase userid `, roomId);
      setRoomId(roomId);
      await FirebaseSignaling.singleton.createRoom(roomId, acceptCall);
      return roomId;
    }
  }

  async function checkPermissions() {
    const hasCameraPermission = await Permissions.query({name: PermissionType.Camera});
    const hasMicPermission = await Permissions.query({name: PermissionType.Microphone});
    console.log('Permissions')
    console.log(hasCameraPermission);
    console.log(hasMicPermission);
    setPermissions({camera: hasMicPermission, mic: hasMicPermission});
  }

  async function getUserMediaForPeer(constraintsPeer) {
    ReactGA.event({
      category: 'Baby Station',
      action: 'Communication',
      label: 'Get User Media for Peer',
      nonInteraction: true
    });
    await checkPermissions();

    let myStream
    if (!streamRef) {
      try {
        myStream = await navigator.mediaDevices.getUserMedia(constraintsUserMedia);
        setStream(myStream)
      } catch (err) {
        console.log('You cannot place/ receive a call without granting video and audio permissions! Please change your settings to use Cuckoo.', err);
        // setModalMessage('You cannot place/ receive a call without granting video and audio permissions! Please change your settings to use Cuckoo.')
        // setModalVisible(true)
      }
      //userVideo.current.srcObject = myStream;
      //return myStream
    } else {
      console.log("accectCall: stream is available reusing stream.");
      showSnackBarMessage("stream is available");
      myStream = streamRef.current;
    }
    // check whether video is false => just return stream with audio
    if (!constraintsPeer.video) {
      myStream = new MediaStream(myStream.getAudioTracks());
    }
    console.log('set meter stream');
    startMeter(myStream);
    return myStream;
  }

  async function clearRoom() {
    const storedRoomId = await getStoredRoomId()
    await FirebaseSignaling.singleton.deleteRoom(storedRoomId);

    await FirebaseSignaling.singleton.createRoom(storedRoomId, () => {
      messagingService.current.listen(roomId, async (event) => {
      }, 'child');
    });

  }

  async function deleteRoom() {
    await FirebaseSignaling.singleton.deleteRoom(roomId);
  }

  useEffect(() => {
    if (connectionStatus) {
      sendBabyStatus(noMlBabyStatus, mlBabyStatus);
      //sendClassifications(classifications);
      sendActivityLog(activityLog.current);
    }
  }, [connectionStatus])

  async function acceptCall(caller, signal, aRoomId) {
    ReactGA.event({
      category: 'Baby Station',
      action: 'Communication',
      label: 'AcceptCall',
      nonInteraction: true
    });
    console.log("AcceptCall");

    // ringtoneSound.unload();
    let theStream = undefined;
    if (Object.keys(signal["userMediaConstraints"]).length !== 0)
      theStream = await getUserMediaForPeer(signal["userMediaConstraints"]);

    setCallAccepted(true);

    peerOnlineEventReceived();
    console.log('Create webrtc peer for incoming call');
    if (myPeer && myPeer.current) myPeer.current.destroy();
    const peerOptions = {
      initiator: false,
      trickle: false,
      //stream: theStream,
      objectMode: true
    }
    if (theStream) peerOptions["stream"] = theStream
    const peer = new Peer(peerOptions);

    myPeer.current = peer;

    peer.on("signal", async (data) => {
      console.log(`received peer signal. Send Accept Call to caller ${caller}. `, data);
      console.log('current room id ', aRoomId);
      await FirebaseSignaling.singleton.signal(aRoomId, data, 'parent');
    })

    peer.on('connect', () => {
      setConnectionStatus(true);
    });

    peer.on("stream", stream => {
      console.log('receive partner video');

      ReactGA.event({
        category: 'Baby Station',
        action: 'Communication',
        label: 'Receive Partner Video',
        nonInteraction: true
      });

      if (partnerVideo.current) {
        partnerVideo.current.srcObject = stream;
      }
      setConnectionStatus(true);
      peerOnlineEventReceived();
    });

    peer.on('close', () => {
      console.log('peer received close event. closing call');
      endCall();
      ReactGA.event({
        category: 'Baby Station',
        action: 'Communication',
        label: `WebRTC close`,
        nonInteraction: true
      });
    });

    peer.on('error', (err) => {
      console.error(['peer error. closing call', err]);
      ReactGA.event({
        category: 'Baby Station',
        action: 'Communication',
        label: `WebRTC Error`,
        nonInteraction: true
      });

    });

    messagingService.current.refreshPeerListener(myPeer);

    console.log('Sate Caller Signal ', callerSignal);
    console.log('Passed Caller Signal ', signal);
    // peer.signal(callerSignal);
    try {
      if (signal && signal.type && signal.type === 'offer') {
        console.log('peer.signal: send answer');
        peer.signal(signal);
      }
    } catch (err) {
      console.log('Unable to send webrtc peer signal ', signal);
    }

  }

  function showUserVideo() {
    if (userVideo.current) {
      // userVideo.current.srcObject = stream;
      userVideo.current.srcObject = streamRef.current;
    }
  }

  const timer = useRef();

  const sendOfflineNotificationToParent = () => {
    // console.log("useChildDevicesStatus: sendOfflineNotificationToParent ", peerDeviceOfflineTimestamp)
    // if last update older than 3 minutes then set offline
    if (peerDeviceOfflineTimestamp !== null && (Date.now() - peerDeviceOfflineTimestamp) > 10 * 1000) {
      console.log('todo: trigger parent notification')
      // sendParentNotification('You got offline. Please reconnect with to baby station.');
    }
  }


  // function processed on mount
  useEffect(() => {
    if (connectionStatus) {
      // send recent babyStatus on new connection
      sendBabyStatus(noMlBabyStatus, mlBabyStatus);
      //sendClassifications(classifications);
      sendActivityLog(activityLog.current);
    }
  }, [connectionStatus]);

  // function processed on mount
  useEffect(() => {
    //setup timer for checking status of child device every n seconds
    setInterval(sendOfflineNotificationToParent, 10 * 1000);

    return () => {
      console.log('component unmount');
      // remove timer
      clearInterval(timer.current);
    }
  }, []);

  function pad(num) {
    return ("0" + num).slice(-2);
  }

  function getTimeFromDate(timestamp) {
    var date = new Date(timestamp * 1000);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();
    return pad(hours) + ":" + pad(minutes) //+":"+pad(seconds)
  }

  function endCall() {
    console.log('End Call');
    if (myPeer.current) {
      myPeer.current.destroy();
      myPeer.current = null;
    }
    setConnectionStatus(false);
    peerOfflineEventReceived();
    // window.location.reload();
    //noSleep.enable();
  }

  async function refreshShareToken() {
    if (roomId) {
      const token = await FirebaseSignaling.singleton.createConnectionToken(roomId);
      setShareToken(token);
    }
  }

  useEffect(() => {
    refreshShareToken().then(r => console.log('refresh share token'));
  }, [roomId]);

  useEffect(() => {
    if (!shareToken) {
      refreshShareToken().then(r => console.log('refresh share token'));
    }
  }, [shareToken]);

  // Permissions.query({ name: PermissionType.Camera }).then((result) => console.log(result));
  // Permissions.query({ name: PermissionType.Microphone }).then((result) => console.log(result));

  const finalBabyStatus = new BabyStatus(noMlBabyStatus, mlBabyStatus);
  return { roomId, shareToken, suspendStream, resumeStream, activityLog, connectionStatus, babyStatus: finalBabyStatus, showUserVideo, subscribeRoom, clearRoom, getPushToken, permissions, messagingService };
}

export default useChildPeer;

import React from 'react';
import {
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonContent,
    IonHeader,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar
} from '@ionic/react';

import './Home.css';
//import firebase from 'firebase';
import firebase from 'firebase/app';
import 'firebase/auth';
import * as firebaseui from 'firebaseui'
import ReactGA from 'react-ga';
import { uiConfig } from "../hooks/useAuth";
import InstallPWA from "../components/InstallPWA";
import ReCAPTCHA from "react-google-recaptcha";

ReactGA.initialize('UA-188532611-1');
ReactGA.pageview(window.location.pathname + window.location.search);

const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth());
/*
ui.start('#firebaseui-auth-container', {
    signInOptions: [
        firebase.auth.EmailAuthProvider.PROVIDER_ID
    ],
    // Other config options...
});
*/

/** email verification functionality not built into default floww
 * see
 * https://stackoverflow.com/questions/43409932/firebase-ui-for-email-verification
 * https://github.com/firebase/firebaseui-web/issues/21
 * https://github.com/firebase/firebaseui-web/search?utf8=%E2%9C%93&q=sendEmailVerification&type=
 *
 */

// The start method will wait until the DOM is loaded.
ui.start('#firebaseui-auth-container', uiConfig);

const Authentication: React.FC = () => {
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="end">
                        <InstallPWA />
                        <IonButton>
                            {/*<IonIcon slot="icon-only" icon={ellipsisHorizontal} name="ellipsisHorizontal" ios="ellipsis-horizontal" md="ellipsis-vertical"></IonIcon>*/}
                        </IonButton>
                    </IonButtons>
                    {/*<IonButton slot="end">*/}
                    {/*    <IonMenuButton color="light" auto-hide="false"></IonMenuButton>*/}
                    {/*</IonButton>*/}
                    <IonRow justify-content-center align-items-center >
                        <a href="/landing_page/index.html">
                            <img src="../assets/image/logo/logo1.svg" style={{ height: "30px", marginLeft: "15px" }} />
                        </a>
                        <IonTitle>
                            <span onClick={() => { window.location.href = '/landing_page/index.html' }}>NiceNice.Baby</span>
                        </IonTitle>
                        {/*
                    <span className="slogan">Giving parents a break</span>
                    */}


                    </IonRow>
                    {/*<IonButton><IonIcon slot="end" icon={star} /></IonButton>*/}
                </IonToolbar>
            </IonHeader>
            <IonContent>

                <IonRow class="ion-justify-content-center">
                    <IonCard>
                        <img src="../assets/image/babystation2.jpg" />
                        <IonCardHeader>
                            {/*<IonCardSubtitle>Destination</IonCardSubtitle>*/}
                            <IonCardTitle>Login</IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent>
                            <ReCAPTCHA
                                sitekey="6Lc_NJQaAAAAACO76UFWgXP_IYGFGrQu2nhkn69H"
                                onChange={(value) => {
                                    console.log("Captcha value:", value);
                                    firebase.auth().signInAnonymously()
                                        .then(() => {
                                            console.log('Anonymous firebase login session created');
                                            window.location.href = '/station/'
                                        })
                                        .catch((error) => {
                                            console.log(`Unable to sign in firebase anonymously`, error);
                                        });
                                }}
                            />
                            <div id="firebaseui-auth-container" style={{display: "none"}}></div>
                            {/*
                        Please sign in with your email and you will receive a login link for our free baby monitoring app.
                        <div id="firebaseui-auth-container"></div>
                            <div id="loader">Loading...</div>
                            */}
                            {/*
                        <IonList>
                            <IonItem>
                                <IonLabel position="floating">Login</IonLabel>
                                <IonInput type="text"
                                          // value={room}
                                          placeholder="Username"
                                          onIonChange={(e) => {
                                              //setRoom(parseInt(e.detail.value!, 10));
                                              // setRoom(e.detail.value);
                                          }}></IonInput>
                            </IonItem>
                            <IonButton onClick={async () => {
                            }}>Sign In</IonButton>
                        </IonList>
*/}
                        </IonCardContent>
                    </IonCard>
                </IonRow>
                {/*
                <IonCard>
                    <IonCardHeader>
                        <IonCardSubtitle>Card Subtitle</IonCardSubtitle>
                        <IonCardTitle>Card Title</IonCardTitle>
                    </IonCardHeader>

                    <IonCardContent>
                        Keep close to Nature's heart... and break clear away, once in awhile,
                        and climb a mountain or spend a week in the woods. Wash your spirit clean.
                    </IonCardContent>
                </IonCard>
*/}

                {/*                <IonCard>
                    <IonItem>
                        <IonIcon icon={pin} slot="start" />
                        <IonLabel>ion-item in a card, icon left, button right</IonLabel>
                        <IonButton fill="outline" slot="end">View</IonButton>
                    </IonItem>

                    <IonCardContent>
                        This is content, without any paragraph or header tags,
                        within an ion-cardContent element.
                    </IonCardContent>
                </IonCard>

                <IonCard>
                    <IonItem href="#" className="ion-activated">
                        <IonIcon icon={wifi} slot="start" />
                        <IonLabel>Card Link Item 1 activated</IonLabel>
                    </IonItem>

                    <IonItem href="#">
                        <IonIcon icon={wine} slot="start" />
                        <IonLabel>Card Link Item 2</IonLabel>
                    </IonItem>

                    <IonItem className="ion-activated">
                        <IonIcon icon={warning} slot="start" />
                        <IonLabel>Card Button Item 1 activated</IonLabel>
                    </IonItem>

                    <IonItem>
                        <IonIcon icon={walk} slot="start" />
                        <IonLabel>Card Button Item 2</IonLabel>
                    </IonItem>
                </IonCard>*/}
            </IonContent>
        </IonPage>
    );
    /*  return (
        <IonPage>
          <IonHeader>
            <IonToolbar>
              <IonTitle>Tab 1</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent fullscreen>
            <IonHeader collapse="condense">
              <IonToolbar>
                <IonTitle size="large">Tab 1</IonTitle>
              </IonToolbar>
            </IonHeader>
            <ExploreContainer name="Tab 1 page" />
          </IonContent>
        </IonPage>
      );*/
};

export default Authentication;


function babyStatusGreater(newStatus, oldStatus) {
    const enumBabyStatus = {
        sleeping: 0,
        awake: 1,
        crying: 2
    };
    const result = enumBabyStatus[newStatus] > enumBabyStatus[oldStatus];
    return result
}

const enumBabyStatusImg = {
    unknown: "/assets/image/baby/baby_awake_1.svg",
    0: "/assets/image/baby/baby_sleeping.svg",
    1: "/assets/image/baby/baby_awake_1.svg",
    2: "/assets/image/baby/baby_crying.svg"
  }

export { babyStatusGreater, enumBabyStatusImg};

import {IonActionSheet, IonAlert, IonButton, IonPage} from "@ionic/react";
import React, {useState} from "react";
import {close as closeIcon, copyOutline, mailOutline, qrCodeOutline, shareOutline} from "ionicons/icons";
import {createParentUrlWithToken, getParentUrl} from "../util/utils";
import {Plugins} from "@capacitor/core";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import Qrcode from "../QRCode";
import Grid from "@material-ui/core/Grid";
import FirebaseSignaling from "../components/FirebaseSignaling";
import ReactGA from "react-ga";
const { Share } = Plugins;

export function ShareActionSheet(props) {
// export function ShareActionSheet({ showCopiedMessage, showActionSheet, setShowActionSheet, roomId }) {

  // const token = await FirebaseSignaling.singleton.createConnectionToken(props.roomId);
  // await FirebaseSignaling.singleton.verifyToken(props.roomId, token);

  console.log('create action sheet');
  const [showSharingInfo, setShowSharingInfo] = useState(false);

  const QRButtonClicked = (event) => {
    console.log('QRButtonClicked');
    setQRDialogOpen(true);
  }

  const EmailButtonClicked =  () => {
    console.log('EmailButtonClicked');

    const emailTo = "";
    const emailSub = "NiceNice.Baby invitation to observe my baby";
    //const emailBody = "https://iceicebaby2.glitch.me/parent?room=" + this.myRoom;
    //const myurl = window.location.origin + "/parent/" + this.myRoom
    // await createParentUrlWithToken(props.roomId);
    //const emailBody = `Link to baby monitoring ${getParentUrl(props.roomId)}`;
    const emailBody = `Dear friend,%0D%0A%0D%0Afollow this link to parent station ${getParentUrl(props.roomId, false, props.shareToken)}.`+
    "%0D%0A%0D%0AKind regards"

    if (navigator.share) {
      navigator.share({
        title: emailSub,
        text: emailBody,
        url: 'url',
      })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    } else {
      //event.preventDefault();
      window.open("mailto:" + emailTo + '?subject=' + emailSub + '&body=' + emailBody, '_blank');
    }
  }

  const shareAPIButton = {
    text: 'Share',
    icon: shareOutline,
    handler: async () => {
      console.log('Share API');
      await Share.share({
        title: 'Baby Monitoring Link',
        text: 'Open this link to start baby monitoring on parent station.',
        url: getParentUrl(props.roomId, false, props.shareToken),
        dialogTitle: 'Baby Monitoring Link'
      }).catch((error) => {
        console.log('Error invoking share API', error);
        /*
                        if (error === "Web Share API not available") {
                            setShowActionSheet(true);
                        }
        */
      });
      // EmailButtonClicked();
    }
  };
  const sendEmailButton = {
    text: 'Send Email',
    icon: mailOutline,
    handler: () => {
      console.log('Send Email');
      EmailButtonClicked();
      ReactGA.event({
        category: 'Baby Station',
        action: 'Share Sheet',
        label: 'Send Email',
        nonInteraction: true
      });
    }
  };
  const shareButton = navigator.canShare ? shareAPIButton : sendEmailButton;
  const actionButtons = [
    {
      text: 'Copy Connection Link',
      // role: 'destructive',
      icon: copyOutline,
      handler: () => {
        console.log('Copy Code');
        // const parentUrl = await createParentUrlWithToken(props.roomId);
        const parentUrl = getParentUrl(props.roomId, false, props.shareToken)
        props.showCopiedMessage(parentUrl);
        setShowSharingInfo(true);
        ReactGA.event({
          category: 'Baby Station',
          action: 'Share Sheet',
          label: 'Copy Connection Link',
          nonInteraction: true
        });
        // props.showCopiedMessage(getParentUrl(props.roomId));
      }
    }, {
    text: 'Copy Connection Code',
    // role: 'destructive',
    icon: copyOutline,
    handler: () => {
      console.log('Copy Code');
      props.showCopiedMessage(props.roomId);
      setShowSharingInfo(true);
      ReactGA.event({
        category: 'Baby Station',
        action: 'Share Sheet',
        label: 'Copy Connection Code',
        nonInteraction: true
      });
    }
  },
    shareButton, {
      text: 'QR Code',
      icon: qrCodeOutline,
      handler: () => {
        console.log('QR Code');
        QRButtonClicked();
        ReactGA.event({
          category: 'Baby Station',
          action: 'Share Sheet',
          label: 'QR Code',
          nonInteraction: true
        });
      }
    }, {
      text: 'Cancel',
      icon: closeIcon,
      // icon: heart,
      role: 'cancel',
      handler: () => {
        console.log('Cancel clicked');
      }
    }];

  const [QRDialogOpen, setQRDialogOpen] = useState(false);

  const handleCloseQRDialog = () => {
    console.log('handleCloseQRDialog');
    setQRDialogOpen(false);
  }

  return <React.Fragment>
          <IonActionSheet
            isOpen={props.showActionSheet}
            onDidDismiss={() => {
              console.log('dismiss action sheet');
              props.setShowActionSheet(false)
            }}
            cssClass='my-custom-class'
            buttons={actionButtons}>
          </IonActionSheet>
          <Dialog
            open={QRDialogOpen}
            onClose={handleCloseQRDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{"Scan link with parent device."}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Qrcode qrtext={getParentUrl(props.roomId, false, props.shareToken)} />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <IonButton color="primary" onClick={handleCloseQRDialog}>Ok</IonButton>
            </DialogActions>
          </Dialog>
          <IonAlert
            isOpen={showSharingInfo}
            onDidDismiss={() => setShowSharingInfo(false)}
            // cssClass='my-custom-class'
            header={'Connection copied'}
            // subHeader={'Subtitle'}
            message={'Please share the connection details now by pasting it into a messenger, email etc in order to share it with another device.'}
            // buttons={['Disagree', 'Agree']}
            buttons={[
              {
                text: 'OK',
                handler: () => {
                  // noSleepEnable(true);
                  setShowSharingInfo(false)
                }
              }
            ]}
          />
      </React.Fragment>;

}

import { useEffect, useRef, useState } from "react";
import MessagingService from "../components/MessagingService";
import useWebPush from "./useWebPush";
//import NoSleep from 'nosleep.js';
import NoiseDetector from "../components/NoiseDetector";
import usePeerDeviceStatus from "./usePeerDeviceStatus";
import FirebaseSignaling from '../components/FirebaseSignaling';
import Peer from "simple-peer";
import ReactGA from "react-ga";
import { BabyStatus } from "../components/BabyStatus";
import {Permissions, PermissionType} from "@capacitor/core";




export const UM_CONSTRAINTS_DATA = {}; //undefined; //{ video: false, audio: false };
export const UM_CONSTRAINTS_AUDIO = { video: false, audio: true };
export const UM_CONSTRAINTS_VIDEO = { video: true, audio: false };
export const UM_CONSTRAINTS_VIDEO_AUDIO = { video: true, audio: true };

function useParentPeer(roomId, userVideo, partnerVideo) {
    const constraintsUserMedia = useRef(UM_CONSTRAINTS_DATA);
    const myPeer = useRef();
    const messagingService = useRef(new MessagingService(myPeer));
    const parentId = useRef(randomId());
    const { messagingWebPush, resendTokenToServer, webPushStatus } = useWebPush(messagingService, parentId, roomId);

    const [stream, setStream] = useState(undefined);
    // unique id of parent

    //const [noSleepButton, setNoSleepButton] = useState(true)
    const { peerDeviceStatus, peerOnlineEventReceived, peerOfflineEventReceived, peerConnectingEventReceived } = usePeerDeviceStatus(myPeer);

    const [classifications, setClassifications] = useState(null);
    const [activityLog, setActivityLog] = useState([]);

    //const [babyStatus, setBabyStatus] = useState("unknown");

    const [noMlBabyStatus, setNoMlBabyStatus] = useState(-1);
    const [mlBabyStatus, setMlBabyStatus] = useState(-1);

    const offerTimeOut = useRef();

    const [permissions, setPermissions] = useState(null);

    async function checkPermissions() {
        const hasCameraPermission = await Permissions.query({name: PermissionType.Camera});
        const hasMicPermission = await Permissions.query({name: PermissionType.Microphone});
        console.log('Permissions')
        console.log(hasCameraPermission);
        console.log(hasMicPermission);
        setPermissions({camera: hasMicPermission, mic: hasMicPermission});
    }

    // on mount of component
    useEffect(async () => {
        console.log("component mount: useParentPeer get audio and video permissions.")
        try {
            navigator.mediaDevices.getUserMedia(UM_CONSTRAINTS_VIDEO_AUDIO).then((stream) => {
                stream.getTracks().forEach(function (track) {
                    track.stop();
                });
            })
        } catch (e) {
            console.log('Permission issue', e);
            try {
                await checkPermissions();
            } catch (e2) {
                setPermissions({ camera: false, mic: false});
            }
        }

        //restore activity log
        if (localStorage.getItem('activitylog')) {
            setActivityLog(JSON.parse(localStorage.getItem('activitylog')));
        }


        // connect to peer
        //createPeerConnection(UM_CONSTRAINTS_DATA);
        //document.addEventListener("visibilitychange", handleVisibilityChange, false);
        return () => {
            console.log('useParentPeer unmount');
            // close mypeer
            endCall();
        }
    }, [])

    async function createPeerConnection(userMediaConstraints) {
        constraintsUserMedia.current = userMediaConstraints;
        // close possible call
        endCall();

        const peer = await callPeer(roomId);//,constraintsUserMediaDataOnly);
        registerRoom();

        return peer
    }

    function acceptCall(signal) {
        console.log('call accepted ', signal);
        //setCallAccepted(true);
        // todo check if myPeer.current.destroyed
        if (myPeer.current) {
            myPeer.current.signal(signal);
        } else {
            console.log('peer gone ', myPeer.current);
            createPeer(stream, roomId, constraintsUserMedia.current);
            myPeer.current.signal(signal);
        }
    }

    function endCall() {
        console.log('endCall');
        if (offerTimeOut.current) {
            console.log("clear offertimeout");
            clearTimeout(offerTimeOut.current);
        }
        if (myPeer && myPeer.current) {
            myPeer.current.destroy();
            myPeer.current = undefined;
            // partnerVideo.current = null;
        }
        if (userVideo && userVideo.current) stopStreamedVideo(userVideo.current)
        if (partnerVideo && partnerVideo.current) stopStreamedVideo(partnerVideo.current)
        setStream(null)
    }


    async function callPeer() {
        ReactGA.event({
            category: 'Parent Station',
            action: 'Communication',
            label: `Call Peer`,
            nonInteraction: true
        });

        const id = parentId.current;
        console.log('calling peer ', id);
        let myStream = undefined;
        // if not data only
        if (Object.keys(constraintsUserMedia.current).length !== 0) {
            // check whether stream is available

            if (!stream) {
                try {
                    myStream = await navigator.mediaDevices.getUserMedia(constraintsUserMedia.current);
                    setStream(myStream);
                    /*const tracks = myStream.getTracks();
                    tracks.forEach(function (track) {
                      track.stop();
                    });*/
                }
                catch (err) {
                    console.log('You cannot place/ receive a call without granting video and audio permissions! Please change your settings to use Cuckoo.')
                    // setModalMessage('You cannot place/ receive a call without granting video and audio permissions! Please change your settings to use Cuckoo.')
                    // setModalVisible(true)
                }

            } else {
                myStream = stream;
            }
            if (myStream) {
                // mute local parent audio
                try {
                    myStream.getAudioTracks()[0].enabled = true; // or false to mute it.
                    myStream.getAudioTracks()[0].stop();
                } catch (e) {
                    console.log(["getAudioTracks:", e]);
                }
                //localStream.getAudioTracks()[0].stop();
                if (userVideo && userVideo.current) {
                    userVideo.current.srcObject = myStream;
                }
            }
        }
        const peer = createPeer(myStream, id, constraintsUserMedia.current);
        //if (noSleepButton) { noSleep.current.disable(); console.log("noSleep.disable()"); }
        return peer
    }

    function createPeer(stream, id, userMediaContraintsPeer2) {
        console.log('Initiate WebRTC Peer');
        if (myPeer && myPeer.current) myPeer.current.destroy();
        const peer_options = {
            initiator: true,
            trickle: false,
            config: {
                iceServers: [
                    { urls: 'stun:stun.l.google.com:19302' },
                    { urls: 'stun:stun1.l.google.com:19302' },
                    { urls: 'stun:stun2.l.google.com:19302' },
                ],
            },
            //stream: stream,
            objectMode: true
        };
        if (stream) peer_options["stream"] = stream;
        let peer = new Peer(peer_options);


        peer.on("signal", async (data) => {
            console.log('emit signal ', data)
            // await FirebaseSignaling.singleton.signal(room, {signal: data, from: yourID});
            // ask child to hava e.g. video and or audio or nothing...
            if (userMediaContraintsPeer2) data["userMediaConstraints"] = userMediaContraintsPeer2


            // wait 5 second for answer then destroy connection and reconnect
            if (data.type = "offer") {
                peerConnectingEventReceived();
                if (offerTimeOut.current) {
                    console.log("clear offertimeout");
                    clearTimeout(offerTimeOut.current);
                }
                offerTimeOut.current = setTimeout(() => {
                    if (!myPeer.current || myPeer.current.destroyed) {
                        console.log("Timeout for connection establishment... reconnect")
                        createPeerConnection(userMediaContraintsPeer2)
                    }
                }, 10000);
            }
            await FirebaseSignaling.singleton.sendOffer(roomId, { signal: data, from: id }, acceptCall);

        });

        peer.on('connect', () => {
            peerOnlineEventReceived();
            if (offerTimeOut.current) {
                console.log("peer.on connect: clear offer timeout");
                clearTimeout(offerTimeOut.current);
            }
        })

        /*
        peer.on("stream", stream => {
            console.log('receive partner video');
            if (partnerVideo.current) {
                partnerVideo.current.srcObject = stream;
            }
        });
        */

        peer.on('error', (err) => {
            ReactGA.event({
                category: 'Parent Station',
                action: 'Communication',
                label: `WebRTC Error`,
                nonInteraction: true
            });

            console.log('error: end call ', err);
            console.log(err);
            // TODO: if err.code === "ERR_CONNECTION_FAILURE" then reconnect
            //peerOfflineEventReceived();
            /*
            endCall();
            stopStreamedVideo(userVideo.current);
            stopStreamedVideo(partnerVideo.current);
            if (err.code === "ERR_CONNECTION_FAILURE") {
                // try to reconnect...
                createPeer(stream, id, userMediaContraintsPeer2)
            }*/
            createPeerConnection(userMediaContraintsPeer2)
        });

        peer.on("connect", () => {
            console.log("peer.on(connected)");
            resendTokenToServer();
        })

        peer.on("close", () => {
            ReactGA.event({
                category: 'Parent Station',
                action: 'Communication',
                label: `WebRTC - Close`,
                nonInteraction: true
            });

            peerOfflineEventReceived();
            if (this !== undefined) {
                //peerOfflineEventReceived();
                endCall();
                stopStreamedVideo(userVideo.current);
                setStream(null)
                stopStreamedVideo(partnerVideo.current);
                //if (noSleepButton) { noSleepEnable(); console.log("noSleep.enable()"); }

            }
        })
        myPeer.current = peer;
        messagingService.current.refreshPeerListener(myPeer);
        /*    peer.on('data', data => {
              // got a data channel message
              myLogFunc('got a message from peer1: ' + JSON.stringify(data));
              //myLogFunc('got a message from peer1: ' + data);
              const jsonData  = JSON.parse(data)
              if (jsonData.type === "classifications") {
                setClassifications(jsonData);
              }
            });*/

        return peer;
    }


    async function registerRoom() {
        ReactGA.event({
            category: 'Parent Station',
            action: 'Communication',
            label: `Connect to Room`,
            nonInteraction: true
        });

        console.log("Room changed");
        if (typeof roomId === 'undefined' || roomId === null) return;

        // initNotifications();
        //initFirebaseMessaging();

        await messagingService.current.listen(roomId, (event) => {
            console.log('messaging service callback receives event');
            //peerOnlineEventReceived();

            if (event && event.type) {
                switch (event.type) {
                    case "classifications":
                        setClassifications(event.classifications);

                        if (NoiseDetector.childNoiseDetected(event, true)) { //settings.current.includeSpeech)) {
                            console.log("Detected relevant noise... starting automatically webrtc call");
                            // emulate click on activeButton to start the webrtc call
                            //createPeerConnection(UM_CONSTRAINTS_VIDEO_AUDIO)
                        } else {
                            console.log("no relevant noise.")
                        }

                        break;
                    case "activity_log":
                        console.log('creating new activity log (activityLog)', activityLog);
                        console.log('creating new activity log (event.activity_log)', event.activity_log);
                        //const newActivityLog = activityLog ? [...activityLog, ...event.activity_log] : event.activity_log;
                        const newActivityLog =event.activity_log;
                        console.log('creating new activity log (newActivityLog)', newActivityLog);
                        setActivityLog(newActivityLog);
                        localStorage.setItem('activitylog', JSON.stringify(newActivityLog.slice(-200)));
                        break;
                    case "baby_status":
                        // setBabyStatus(event.babyStatus);
                        // console.log(`received baby_status: ${event.no_ml_babyStatus}/${event.ml_babyStatus}`)
                        setMlBabyStatus(event.ml_babyStatus)
                        setNoMlBabyStatus(event.no_ml_babyStatus)
                        break;
                }
            }
        }, 'parent');

        //send the web push token to child of changed room
        //resendTokenToServer();


    }//, [room]);

    function stopStreamedVideo(videoElem) {

        if (videoElem && videoElem.srcObject) {
            const stream = videoElem.srcObject;
            if (stream) {
                const tracks = stream.getTracks();
                tracks.forEach(function (track) {
                    track.stop();
                });

                if (videoElem) videoElem.srcObject = null;
            }
        }

    }

    function randomId() {
        const uint = window.crypto.getRandomValues(new Uint32Array(1))[0];
        return uint.toString(16);
    }


    let finalBabyStatus = new BabyStatus(noMlBabyStatus, mlBabyStatus);
    return { peerDeviceStatus,
        //babyStatus,
        // noMlBabyStatus,
        // mlBabyStatus,
        babyStatus: finalBabyStatus,
        classifications,
        activityLog,
        createPeerConnection,
        endCall,
        webPushStatus,
        permissions
    };
}

export default useParentPeer;

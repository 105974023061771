import worker from "workerize-loader!../web-worker-audioclassification.js"; // eslint-disable-line import/no-webpack-loader-syntax
import utils from "./audiometer/web-audio-peak-meter/utils"; // eslint-disable-line import/no-webpack-loader-syntax
//import { AudioContext, OfflineAudioContext } from 'standardized-audio-context';
// import {quantileSeq} from 'mathjs'
// import { NONAME } from "dns";

class NoiseDetector {
    static crying_ids = new Set(["/m/0463cq4", "/t/dd00002", "/m/07qz6j3", "/m/09x0r"]);///m/09x0r" is speach

    constructor(anAudiocontext, parStream, noiseCallBack, notificationMessage, config, meterData = null) {
        this.meterData = meterData;
        // this.element = null;

        this.initialized = false;
        this.notificationMessage = notificationMessage

        if (parStream !== undefined) {
            console.log("Stream parameter defined. Saving it.")
            this.stream = parStream;
        }

        // this.window = window
        this.disabledML = true //false //!("AudioContext" in window);
        this.audioSampleRate = 44100;//16000;
        this.audioConstraint = {
            latencyHint: "interactive",
            //sampleRate: this.audioSampleRate
        }


        // this.AudioContext = (window.AudioContext || window.webkitAudioContext)
        this.AudioContext = anAudiocontext
        this.audioContext = null
        console.log("Creating worker for ML inference.");
        this.workerInstance = null; //worker(); // Attach an event listener to receive calculations from your worker
        this.audioBuffer = null;
        this.suspendDetecting = this.suspendDetecting.bind(this);
        this.resumeDetecting = this.resumeDetecting.bind(this);
        this.checkForNoise = this.checkForNoise.bind(this)
        this.audioBuffer = null;

        this.audioBuffer_max_length = this.audioSampleRate * 2;
        this.is_model_running = false;

        this.userMediaConstraint = {
            audio: true,
            video: false
        }
        //https://developer.mozilla.org/en-US/docs/Web/API/AnalyserNode/minDecibels
        this.min_decibels = -100;
        this.volume = 0;
        this.averaging = 0.95;
        this.checkIntervall = 1000;
        this.smoothingTimeConstant = 0.2
        this.noiseCallBack = noiseCallBack;

        console.log(typeof this.audioContext);

        if (config)
            if (config.disabledML)
                this.disabledML = true

        //if (!this.disabledML) {
            console.log("Starting ML worker...")
            this.workerInstance = worker(); // Attach an event listener to receive calculations from your worker
            this.workerInstance.loadModel().then(() => {
                if (this.notificationMessage) {
                    // this.notificationMessage("ML model loaded!")
                }
            });
        //}
        this.ml_result = {}
    }
/*
    SetDisabledML(enabled) {
        this.disabledML = enabled;
    }
    updateSettings(settings) {
        // ml classification switch
        this.SetDisabledML(!settings.MLclassification);

        // noise threshold
        if (this.analyser !== undefined) {
            this.analyser.minDecibels = settings.noiseThreshold;
        }
    }
    */
    async initialize_() {

        this.audioContext = new this.AudioContext( //new (window.AudioContext || window.webkitAudioContext)
            this.audioConstraint
        );

        //https://bugs.webkit.org/show_bug.cgi?id=179363

        if (this.stream === undefined) {
            console.log("No stream available neither stored or in parameter. Calling getusermedia")
            this.stream = await navigator.mediaDevices.getUserMedia(this.userMediaConstraint);
        }

        if (this.audioContext.state === "closed") {
            console.log(": state is closed!");
            return;
        }
        console.log("audioContext.createMediaStreamSource(stream)");
        const source = this.audioContext.createMediaStreamSource(this.stream);
        console.log("audioContext.createAnalyser();");

        this.analyser = this.audioContext.createAnalyser();
        //this.analyser.fftSize = 32768;

        this.analyser.minDecibels = this.min_decibels
        this.analyser.smoothingTimeConstant = this.smoothingTimeConstant //  default is 0.8

        console.log("child.js: source.connect(this.analyser).connect(audioContext.destination)");
        //if (!this.disabledML) {
            source.connect(this.createMyNode()).connect(this.analyser)//.connect(this.audioContext.destination);

        //} else {
        //    source.connect(
        //        this.analyser)//.connect(this.audioContext.destination);
        //}
        console.log("Audio module started");
        this.initialized = true;
        //this.suspendDetecting();


    }

    /*
    static childNoiseDetected(result, includeSpeech = true) {
        if (!result.classifications) return false;

        // include speech recognition
        const my_crying_ids = new Set(NoiseDetector.crying_ids);
        if (includeSpeech) {
            // add to set
            my_crying_ids.add("/m/09x0r");
        } else {
            //remove from set, if exists
            if (my_crying_ids.has("/m/09x0r")) {
                my_crying_ids.delete("/m/09x0r")
            }
        }

        var jpath = require('json-path')
        const result_filtered = result.classifications.filter(obs => obs.probability > 0.2);
        //const res = jpath.resolve(result_filtered, "#/classifications[0]/mid")
        const res = jpath.resolve(result_filtered, "[]/mid")


        if (res.filter(value => my_crying_ids.has(value)).length > 0) {
            return true
        }
        else {
            return false
        }

    }
    */
    static relevantChange(old_classification, classifications) {
        //check if top classification category has changed
        return old_classification[0].mid !== classifications[0].mid;
    }

    suspendDetecting() {

        clearInterval(this.checkForNoiseInterval);

        /*
          if (this.audioContext === "running") {
              this.audioContext.suspend();
              }
          //this.audioContext.close();

          */
        this.initialized = false;
    }

    async resumeDetecting() {

        if (this.initialized === false) {
            await this.initialize_();
        }
        /*else {
              if (this.audioContext === "suspended") {
                this.audioContext.resume();
              }

          }
          */

        this.checkForNoiseInterval = setInterval(this.checkForNoise, this.checkIntervall);

    }
    close() {
        clearInterval(this.interval);
        this.workerInstance.terminate();
        this.audioContext?.close();
    }

    componentWillUnmount() {
        this.close();
    }


    createMyNode(bufferSize = 16384) {
        var scriptNode = this.audioContext.createScriptProcessor(bufferSize); //, 1, 1);

        const myOnAudio = function (audioProcessingEvent) {
            //this.volumeAudioProcess(audioProcessingEvent)
            //console.log("Mynode: onaudioprocess");

            var inputBuffer = audioProcessingEvent.inputBuffer;

            // The output buffer contains the samples that will be modified and played
            var outputBuffer = audioProcessingEvent.outputBuffer;

            // Loop through the output channels (in this case there is only one)
            for (var channel = 0; channel < outputBuffer.numberOfChannels; channel++) {
                var inputData = inputBuffer.getChannelData(channel);
                //outputBuffer.copyToChannel(inputData, channel)

                var outputData = outputBuffer.getChannelData(channel);

                // Loop through the 4096 samples
                for (var sample = 0; sample < inputBuffer.length; sample++) {
                    // make output equal to the same as the input
                    outputData[sample] = inputData[sample];
                }
            }


            if (this.audioBuffer === null) {
                this.audioBuffer = audioProcessingEvent.inputBuffer.getChannelData(0);
            } else {
                this.audioBuffer = Float32Array.from([
                    ...this.audioBuffer,
                    ...audioProcessingEvent.inputBuffer.getChannelData(0)
                ]);

                if (this.audioBuffer.length > this.audioBuffer_max_length) {
                    this.audioBuffer = this.audioBuffer.slice(
                        this.audioBuffer.length - this.audioBuffer_max_length
                    );
                }
            }

        }
        scriptNode.onaudioprocess = myOnAudio.bind(this);
        return scriptNode;
    }

    async dummyCheckForNoise() {
        console.log("dummyCheckForNoise")
    }

    // calculate current volume
    volumeAudioProcess( event ) {
        var buf = event.inputBuffer.getChannelData(0);
        var bufLength = buf.length;
        var sum = 0;
        var x;

        // Do a root-mean-square on the samples: sum up the squares...
        for (var i=0; i<bufLength; i++) {
            x = buf[i];
            /*
            if (Math.abs(x)>=this.clipLevel) {
                this.clipping = true;
                this.lastClip = window.performance.now();
            }
            */
            sum += x * x;
        }

        // ... then take the square root of the sum.
        var rms =  Math.sqrt(sum / bufLength);

        // Now smooth this out with the averaging factor applied
        // to the previous sample - take the max here because we
        // want "fast attack, slow release."
        this.volume = Math.max(rms, this.volume*this.averaging);
        console.log(["volume=",this.volume])
    }

    async checkForNoise() {
        let silence_classification = [{
            class: 494,
            mid: "/m/028v0c",
            name: "Silence",
            probability: 1.0,
            rank: 0
        }]
        let noise_classification = [{
            class: -1,
            mid: "/m/0ytgt", //"/m/0463cq4",
            name: "Noise",
            probability: 1.0,
            rank: 0
        }]
        let no_ml_result = null

        const hasSignificantLoudness = (threshold) => {
            return function (element, index, array) {
                // console.log(`hasSignificantLoudness ${element} > ${threshold}: ${element > threshold}`);
                // console.log(`hasSignificantLoudness ${element}`);
                // this.element = element;
                return (element > threshold);
            }
        }

        const test_silence_data = new Uint8Array(this.analyser.frequencyBinCount);
        this.analyser.getByteFrequencyData(test_silence_data)
        // Check whether a significant noise level was reached

        // console.log(`meterdata ${this.meterData && meterDb}`);
        let isNoisy = this.isNoisy(test_silence_data, hasSignificantLoudness);
        if (isNoisy === null) return; //  no meter data available

        if (isNoisy) { // if there is data above the given db limit

            // test_silence_data.some((element, index, array) => {console.log(`test ${element}`);return;});
            // console.log("there was noise")

            //if (true) { //(this.disabledML ) {
            //     console.log(`meterdata ${this.meterData && this.meterData.current}`);
            //     console.log(`meterdata ${this.meterData && meterDb}`);
            //quantileSeq(Array.from(test_silence_data), 0.99)*1.5
            if (isNoisy) {
                    no_ml_result = {//this.noiseCallBack({
                        classifications: noise_classification,
                        disabledML: this.disabledML, noiseAboveThreshold: true
                    }//)
                }
            //} else
                if (this.audioBuffer !== null && this.audioBuffer.length >= this.audioBuffer_max_length) {
                    if (this.is_model_running) {
                        // console.log("model is still running... skipping...");
                    } else {
                        this.is_model_running = true;
                        // console.log("run model");

                        const resampledAudioBuffer = await this.resampling(this.audioBuffer, this.audioContext.sampleRate, 16000);

                        this.workerInstance
                            //.runModel(this.audioBuffer)
                            .runModel(resampledAudioBuffer)
                            .then(top_classifications => {
                                this.is_model_running = false;

                                // console.log("Received  top classifications from worker runModel");

                                this.ml_result = { //this.noiseCallBack({
                                    classifications: top_classifications,
                                    disabledML: false,
                                    noiseAboveThreshold: true
                                }//)

                                console.log(top_classifications);
                            });


                    }
                };
        } else {
            // console.log("there was NO noise")
            no_ml_result = //this.noiseCallBack(
                { classifications: silence_classification, disabledML: this.disabledML, noiseAboveThreshold: true }
                //)
        }
        this.noiseCallBack({no_ml_result: no_ml_result, ml_result: this.ml_result})
    }


    isNoisy(test_silence_data, hasSignificantLoudness) {
        if (this.meterData && this.meterData.current) {
            const meterDb = utils.dbFromFloat(this.meterData.current.tempPeaks[0]);
            // console.log('meterdata', this.meterData.current);
            // console.log('config', this.meterData.current.config);
            if (this.meterData.current.config) console.log('config threeshold ', this.meterData.current.config.noiseAlertThresholdDb);
            const noiseAlertThresholdDb = (this.meterData.current.config) ? this.meterData.current.config.noiseAlertThresholdDb : -40;
            // const noiseAlertThresholdDb = (parseInt(meterdata.config.noiseAlertThresholdDb) <= -40) ? -70 : meterdata.config.noiseAlertThresholdDb;
            console.log(`${meterDb} > noiseAlertThresholdDb ${noiseAlertThresholdDb} / ${this.meterData.current.config.noiseAlertThresholdDb} / ${utils.dbFromFloat(noiseAlertThresholdDb)} -- ${utils.dbFromFloat(0.00001)}`)
            console.log(`result ${meterDb >= noiseAlertThresholdDb}`)
            // console.log(meterDb);
            // console.log(noiseAlertThresholdDb);
            // return (meterDb >= -40);
            if (isFinite(meterDb) || meterDb !== Infinity) {
                return (meterDb >= noiseAlertThresholdDb);
            } else {
                return null;
            }
        } else {
            return test_silence_data.some(hasSignificantLoudness(120));
        }
    }

    createAudioContext(anAudioContext, audioConstraint) {
        console.log('create Audiocontext');
        // var AudioCtor = window.AudioContext || window.webkitAudioContext
        var AudioCtor = anAudioContext;

        const desiredSampleRate = typeof audioConstraint.sampleRate === 'number'
            ? audioConstraint.sampleRate
            : 44100
        var context = new AudioCtor(audioConstraint)

        // Check if hack is necessary. Only occurs in iOS6+ devices
        // and only when you first boot the iPhone, or play a audio/video
        // with a different sample rate
        if (/(iPhone|iPad)/i.test(navigator.userAgent) &&
            context.sampleRate !== desiredSampleRate) {
            var buffer = context.createBuffer(1, 1, desiredSampleRate)
            var dummy = context.createBufferSource()
            dummy.buffer = buffer
            dummy.connect(context.destination)
            dummy.start(0)
            dummy.disconnect()

            context.close() // dispose old context
            context = new AudioCtor(audioConstraint)
        }

        return context
    }



    /**
     * Resample using OfflineAudioContextac
     */
    async resampling(channelData, inSampleRate, outSampleRate) {
        const channels = 1
        const outNumSamples = Math.floor(channelData.length * outSampleRate / inSampleRate)
        //const OfflineAudioContext = require("web-audio-engine").OfflineAudioContext;
        const o = new OfflineAudioContext(channels, outNumSamples, outSampleRate);

        // create audio buffer
        var b = o.createBuffer(channels, channelData.length, inSampleRate);
        b.copyToChannel(channelData, 0);
        // copy data
        /*
        for (var channel = 0; channel < channels; channel++) {
            var buf = b.getChannelData(channel);
            for (var i = 0; i < channelData.length; i++) {
                buf[i] = channelData[i];
            }
        }
        */


        /* Play it from the beginning. */
        var source = o.createBufferSource();
        source.buffer = b;
        source.connect(o.destination);
        source.start(0);
        //var result_audiobuffer = null;
        //o.oncomplete = function (audiobuffer) {
        /* audiobuffer contains resampled audio, use
         * audiobuffer.getChannelData(x) to get an ArrayBuffer for
         * channel x.
         */
        //result_audiobuffer = audiobuffer
        //}

        /* Start rendering as fast as the machine can. */
        const result_audiobuffer = await o.startRendering();
        const result_channelData = result_audiobuffer.getChannelData(0)
        return result_channelData
    }
}

export default NoiseDetector;

import NoSleep from 'nosleep.js';
import { useEffect, useRef } from 'react';



function useSleepEnable() {

    const noSleep = useRef(new NoSleep());

    function recreateNoSleep() {
        noSleep.current = new NoSleep();
    }
    function noSleepEnable(enable = true) {

        if (enable)
            noSleep.current.enable().catch((err) => {
                console.error(`${err.name}, ${err.message}`);
            });
        else noSleep.current.disable();
    }
    useEffect( () => {

        return () => {
            console.log('useSleepEnable unmount');
            noSleep.current.disable();
        }
    },[])

    return { noSleepEnable,  recreateNoSleep}

}

export default useSleepEnable;
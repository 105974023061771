// src/count/count-context.js
import * as React from 'react';
import {useEffect, useState} from "react";

import { Plugins } from '@capacitor/core';
const { Storage } = Plugins;

async function setObject(settingsConfig) {
  // console.log('Persists settings config', settingsConfig);
  // await Storage.remove({
  //   key: 'settings'
  // });
  let options = {
    key: 'settings',
    value: JSON.stringify(settingsConfig)
    // value: settingsConfig
  };
  // console.log('Persists settings config', options);
  await Storage.set(options);
  // console.log('Persisted settings config', options);
  const ret = await Storage.get({ key: 'settings' });
  // console.log('Persisted value check: ', ret);

}

// JSON "get" example
async function getObject(context) {
  const ret = await Storage.get({ key: 'settings' });
  // console.log('Returned from storage ', ret);
  const settingsConfig = JSON.parse(ret.value);
  return settingsConfig;
}

const UseSettings = React.createContext();

function useSettings() {
  // console.log('useSettings');
  const [restoredPersistedState, setRestoredPersistedState ] = useState(false);
  const context = React.useContext(UseSettings);
  if (!context) {
    throw new Error(`useCount must be used within a CountProvider`)
  }

  useEffect(() => {
    if (restoredPersistedState) {
      // console.log("persistState(STORAGE_KEY, state)");
      const [
        noiseDetectionEnabled, setNoiseDetectionEnabled,
        noiseThreshold, setNoiseThreshold,
        classificationEnabled, setClassificationEnabled,
        includeSpeechEnabled, setIncludeSpeechEnabled,
        developmentMode, setDevelopmentMode
      ] = context;
      const settingsConfig = {
        noiseDetectionEnabled,
        noiseThreshold,
        classificationEnabled,
        includeSpeechEnabled,
        developmentMode
      };
      setObject(settingsConfig);
      // persistState(STORAGE_KEY, state)
    }
  }, [context, restoredPersistedState]);
// }, [context.noiseDetectionEnabled, context.noiseThreshold, context.classificationEnabled, context.includeSpeechEnabled, context.developmentMode, restoredPersistedState])


  if (!restoredPersistedState) {
    getObject().then((settings) => {
      // console.log('restore settings ', settings);

      const [
        noiseDetectionEnabled, setNoiseDetectionEnabled,
        noiseThreshold, setNoiseThreshold,
        classificationEnabled, setClassificationEnabled,
        includeSpeechEnabled, setIncludeSpeechEnabled,
        developmentMode, setDevelopmentMode
      ] = context;

      if (settings) {
        setNoiseDetectionEnabled(settings.noiseDetectionEnabled);
        setNoiseThreshold(settings.noiseThreshold);
        setClassificationEnabled(settings.classificationEnabled);
        setIncludeSpeechEnabled(settings.includeSpeechEnabled);
        setDevelopmentMode(settings.developmentMode);
      }
      setRestoredPersistedState(true);
    });
  }

  return context;
}

function useSettingsConfig() {
  const [
    noiseDetectionEnabled, setNoiseDetectionEnabled,
    noiseThreshold, setNoiseThreshold,
    classificationEnabled, setClassificationEnabled,
    includeSpeechEnabled, setIncludeSpeechEnabled,
    developmentMode, setDevelopmentMode
  ] = useSettings();
  const settingsConfig = {
    noiseDetectionEnabled, setNoiseDetectionEnabled,
    noiseThreshold, setNoiseThreshold,
    classificationEnabled, setClassificationEnabled,
    includeSpeechEnabled, setIncludeSpeechEnabled,
    developmentMode, setDevelopmentMode
  };
  // console.log('Current Settings: ', settingsConfig);
  return settingsConfig;
}

function SettingsProvider(props)  {
  // console.log('create settingsprovide');
  const [noiseDetectionEnabled, setNoiseDetectionEnabled] = React.useState(true);
  const [noiseThreshold, setNoiseThreshold] = React.useState(0);
  const [classificationEnabled, setClassificationEnabled] = React.useState(true);
  const [includeSpeechEnabled, setIncludeSpeechEnabled] = React.useState({ alertMode: 'noise', json: true, test: false});
  const [developmentMode, setDevelopmentMode] = React.useState(false);

  const value = React.useMemo(() =>
    [
      noiseDetectionEnabled, setNoiseDetectionEnabled,
      noiseThreshold, setNoiseThreshold,
      classificationEnabled, setClassificationEnabled,
      includeSpeechEnabled, setIncludeSpeechEnabled,
      developmentMode, setDevelopmentMode
    ],
    [noiseDetectionEnabled, noiseThreshold, classificationEnabled, includeSpeechEnabled, developmentMode]
  );
  return <UseSettings.Provider value={value} {...props} />;
}

export {SettingsProvider, useSettings, useSettingsConfig};

const translations = {
  "sleeping": {
    "en": "no baby noises detected",
  },
  "noise1": {
    "en": "noise",
  },
  "noise2": {
    "en": "load noise",
  },
  "webpush.status.0": {
    "en": "initializing",
  },
  "webpush.status.1": {
    "en": "On",
  },
  "webpush.status.2": {
    "en": "Unavailable",
  },
  "aiStatus.0": {
    "en": "no crying",
  },
  "aiStatus.1": {
    "en": "stopped crying",
  },
  "aiStatus.2": {
    "en": "crying",
  },
  "noiseStatus.0": {
    "en": "silence",
  },
  "noiseStatus.1": {
    "en": "noise",
  },
  "noiseStatus.2": {
    "en": "noise",
  },
  "babystation.alertMode": {
    "en": "Push-Notification Alert-Mode"
  },
  "parentstation.alertMode": {
    "en": "Audio-Notification Alert-Mode"
  },
  "babystation.description": {
    "en": "Audio-Notification Alert-Mode can be adjusted in Settings on ParentStation."
  },
  "parentstation.description": {
    "en": "Push-Notification Alert-Mode can be adjusted in Settings on BabyStation."
  }
};

const __ = function (val, { locale, substitution } =  {}) {
  // console.log('translate');

  if (!translations[val]) {
    return val;
  }


  /* we fallback to german locale if locale is not provided*/
  let finalTranslation =
    translations[val][locale] || translations[val]["en"]
      ? translations[val][locale] || translations[val]["en"]
      : val;

  if (substitution && substitution.length > 0) {
    substitution.map((sub) => {
      finalTranslation = finalTranslation.replace(/%s/, sub);
    });
  }

  return finalTranslation;
};

module.exports = __;

import React, {useEffect, useState} from 'react';
import {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonItem,
    IonList,
    IonLabel,
    IonToggle,
    IonCheckbox,
    IonRange,
    IonIcon,
    IonItemDivider,
    IonSelectOption, IonSelect
} from '@ionic/react';
import './Settings.css';
import {
    brush,
    contrast,
    sunny,
    thermometer,
    volumeLowOutline,
    volumeHighOutline,
    alertCircleOutline,
    checkmarkCircleOutline
} from "ionicons/icons";
import {useSettingsConfig} from "../hooks/useSettings";
import { useAuthRequired } from "../hooks/useAuth";
import WebPushStatus from "../hooks/WebPushStatus";
import __ from '../components/Translate'

// Settings
// Noise Detection:
// Noise Sensivity
// NoiseDetection (default on)

// General
// AI Crying Detection
// Stream Babyphone
// Automatic Stream

// Notification
// Baby unit disconnects from charging
// Baby unit battery level is below 10%
// Baby unit noise level exceedds a set threshold
// Baby unit disconnects from monitoring

// Local Settings
// Vibration alert
// Automatic unmute when child is active
// Alert the use of mobile data for video monitoring
// Enable sound notifications
// Screen auto-dimming

// Motion Detection
// Motion detection during monitoring


const Settings: React.FC = (props) => {

    console.log('settings');

    useAuthRequired();

    const {
        noiseThreshold, setNoiseThreshold,
        noiseDetectionEnabled, setNoiseDetectionEnabled,
        classificationEnabled, setClassificationEnabled,
        includeSpeechEnabled: config, setIncludeSpeechEnabled: setConfig,
        developmentMode, setDevelopmentMode
    } = useSettingsConfig();

    // const [alertMode, setAlertMode] = useState<string[]>(['noise', 'crying']);

    // const [alertMode, setAlertMode] = useState<string>(config.alertMode ? alertMode : 'noise');

    const [value, setValue] = useState(0);
    const [rangeValue, setRangeValue] = useState<{
        lower: number;
        upper: number;
    }>({ lower: 0, upper: 0 });

    // const [noiseSensitivity, c] = useState<number>(0);
    const [noiseSensitivityDescription, setNoiseSensitivityDescription] = useState<String>('Reaction to any noise');

/*    useEffect(() => {
        try {
            if (props.messagingService && props.roomId) {
                props.messagingService.current.send({
                    //type: "pushToken",
                    type: "settings",
                    settings: config
                }, props.roomId, 'child', {times : 5, wait : 2000}, true )
                .then(e => console.log('sent settings'))
                .error(e => console.log("Unable to send settings", e));
            }
        } catch (e) {
            console.log("Unable to send settings", e);
        }
    }, [config.alertMode])*/

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Settings</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
          <div style={{display: "none"}}>
              <div id="firebaseui-auth-container"></div>
              <div id="loader">Loading...</div>
          </div>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Settings</IonTitle>
          </IonToolbar>
        </IonHeader>
          <IonList>
              {/*}
              <IonItemDivider color="light"><IonLabel>Noise Sensitivity</IonLabel></IonItemDivider>
              <IonItem>
                  <IonRange pin value={noiseThreshold} onIonChange={e => {
                      setNoiseThreshold(e.detail.value as number);
                      if (e.detail.value >= 0 && e.detail.value <= 33) {
                          setNoiseSensitivityDescription('Reaction to any noise');
                      } else if (e.detail.value >= 34 && e.detail.value <= 66) {
                          setNoiseSensitivityDescription('Reaction to speaking');
                      } else if (e.detail.value >= 66 && e.detail.value <= 100) {
                      setNoiseSensitivityDescription('Reaction to loud crying');
                  }
                  }} >
                      <IonIcon size="small" slot="start" icon={volumeLowOutline} />
                      <IonIcon slot="end" icon={volumeHighOutline} />
                  </IonRange>
              </IonItem>
              <IonItem>
                  <IonLabel>{noiseScensitivityDescription}</IonLabel>
              </IonItem>
                */}
              <IonItemDivider color="light"><IonLabel>Noise Detection</IonLabel></IonItemDivider>
{/*
              <IonItem>
                  <IonLabel>Noise Detection</IonLabel>
                  <IonToggle slot="start" name="blueberry"
                             checked={noiseDetectionEnabled}
                             onIonChange={e => {
                                 setNoiseDetectionEnabled(e.detail.checked);}
                             } >
                  </IonToggle>
              </IonItem>
*/}
              {/*<IonItemDivider color="light"><IonLabel>Alert Mode</IonLabel></IonItemDivider>*/}
              <IonItem>
                  <IonLabel>{__(`${props.mode}.alertMode`)}</IonLabel>
                  <IonSelect
                            // value={alertMode}
                             value={config.alertMode}
                             placeholder="Select One"
                             onIonChange={e => {
                      console.log('update alert mode');
                      // setAlertMode(e.detail.value);
                      const alertConfig = { alertMode: e.detail.value };
                      const newConfig = { ...config, ...alertConfig };
                      setConfig(newConfig);
                      console.log(config);
                  }}>
                      <IonSelectOption value="noise">Any Noise</IonSelectOption>
                      <IonSelectOption value="crying">Only Crying</IonSelectOption>
                  </IonSelect>
{/*
                  <IonToggle slot="start" name="blueberry"
                             checked={config.alertMode}
                             onIonChange={e => {
                                 const alertConfig = { alertMode: !config.alertMode };
                                 const newConfig = { ...config, ...alertConfig };
                                 setConfig(newConfig);
                             }} >
                  </IonToggle>
*/}
              </IonItem>
              {/*<IonItem>*/}
                  {/*<IonLabel>*/}
                      <IonIcon className="icon" size="small" icon={alertCircleOutline} /><span style={{ fontSize: "small", fontStyle: "italic" }}>{__(`${props.mode}.description`)}</span>
                  {/*</IonLabel>*/}
              {/*</IonItem>*/}
              {/*
              <IonItem>
                  <IonLabel>AI Crying Detection</IonLabel>
                  <IonToggle slot="start" name="blueberry"
                             checked={classificationEnabled}
                             onIonChange={e => {
                                 setClassificationEnabled(e.detail.checked);}
                             }
                  >

                  </IonToggle>
              </IonItem>
              <IonItem>
                  <IonLabel>Automatic Audio Stream on Noise</IonLabel>
                  <IonToggle slot="start" name="blueberry"
                             checked={includeSpeechEnabled}
                             onIonChange={e => {
                                 setIncludeSpeechEnabled(e.detail.checked);}
                             }
                  >
                  </IonToggle>
              </IonItem>
                            */}
{/*
              <IonItemDivider color="light"><IonLabel>Local Settings</IonLabel></IonItemDivider>
              <IonItem>
                  <IonLabel>Development Mode</IonLabel>
                  <IonToggle slot="start" name="apple" color="secondary"
                             checked={developmentMode}
                             onIonChange={e => {
                                 setDevelopmentMode(e.detail.checked);}
                             }
                  />
              </IonItem>*/}
              {/*
              <IonItem>
                  <IonLabel>Baby unit disconnects from charging</IonLabel>
                  <IonToggle slot="start" name="apple" color="secondary" checked></IonToggle>
              </IonItem>

              <IonItem>
                  <IonLabel>Baby unit battery level is below 10%</IonLabel>
                  <IonToggle slot="start" name="grape" color="tertiary" checked></IonToggle>
              </IonItem>

              <IonItem>
                  <IonLabel>Baby unit noise level exceeds a set threshold</IonLabel>
                  <IonToggle slot="start" name="kiwi" color="success" checked></IonToggle>
              </IonItem>

              <IonItem>
                  <IonLabel>Baby unit disconnects from monitoring</IonLabel>
                  <IonToggle slot="start" name="banana" color="warning" checked></IonToggle>
              </IonItem>

              <IonItemDivider color="light"><IonLabel>Notification</IonLabel></IonItemDivider>
              <IonItem>
                  <IonLabel>Vibration alert</IonLabel>
                  <IonToggle slot="start" name="cherry" color="danger" checked></IonToggle>
              </IonItem>

              <IonItem>
                  <IonLabel>Automatic unmute when child is active</IonLabel>
                  <IonToggle slot="start" name="banana" color="light" checked></IonToggle>
              </IonItem>

              <IonItem>
                  <IonLabel>Alert the use of mobile data for video monitoring</IonLabel>
                  <IonToggle slot="start" name="cantaloupe" color="medium" checked></IonToggle>
              </IonItem>


              <IonItem>
                  <IonCheckbox color="secondary" checked slot="start"></IonCheckbox>
                  <IonLabel>Screen auto-dimming</IonLabel>
              </IonItem>

              <IonItemDivider color="light"><IonLabel>Motion Detection</IonLabel></IonItemDivider>
              <IonItem>
                  <IonLabel>Motion detection during monitoring</IonLabel>
                  <IonToggle slot="start" name="blackberry" color="dark" checked></IonToggle>
              </IonItem>
              */}
          </IonList>
        {/*<ExploreContainer name="Tab 3 page" />*/}
      </IonContent>
    </IonPage>
  );
};

export default Settings;

import React, {useEffect, useRef, useState} from "react";

export function BabyStatusSection(props) {

    const [currentDate, setCurrentDate] = useState(new Date());
    const intervalId = useRef<any>();

    useEffect(() => {
        intervalId.current = setInterval(
            () => tick(),
            1000
        );

        return () => {
            clearInterval(intervalId.current);
        }
    }, []);

    function tick() {
        setCurrentDate(new Date());
    }

    if (isDetectionEnabled()) {
        const currentDuration = currentStatusDuration();

/*        return <fragment>
            <section style={{ marginTop: "10px" }}>
                <h3 style={{ textTransform: 'uppercase', fontSize: "x-small" }}>Noise Baby Status</h3>
                <p style={{ fontWeight: "bold" }}>{ props.babyStatus.noiseBabyStatus >= 0 ? `Baby station detected ` + props.babyStatus.getNoiseStatusMessage() : "Audiostream not available. Classification not started."}</p>
            </section>
            <section style={{ marginTop: "10px" }}>
                <h3 style={{ textTransform: 'uppercase', fontSize: "x-small" }}>AI Baby Status</h3>
                <p style={{ fontWeight: "bold" }}>{ props.babyStatus.aiBabyStatus >=0 ? `` + __(`aiStatus.${props.babyStatus.aiBabyStatus}`) : "Audiostream not available. Classification not started."}</p>
            </section>
        </fragment>;*/
        return <>
            <section style={{ marginTop: "10px" }}>
                <h3 style={{ textTransform: 'uppercase', fontSize: "x-small" }}>Noise Baby Status</h3>
                <p style={{ fontWeight: "bold" }}>Baby station detected {props.babyStatus.getNoiseStatusMessage()}
                    {currentDuration > 0 && ` (at ${currentStatusDate()} | ${currentDuration} seconds ago)`}
                    {currentDuration === 0 && ` (at ${currentStatusDate()})`}
                </p>
            </section>
            <section style={{ marginTop: "10px" }}>
                <h3 style={{ textTransform: 'uppercase', fontSize: "x-small" }}>AI Baby Status</h3>
                <p style={{ fontWeight: "bold" }}>{props.babyStatus.getAiStatusMessage()}</p>
            </section>
        </>;
    } else {
        return <>
            <section style={{ marginTop: "10px" }}>
                <h3 style={{ textTransform: 'uppercase', fontSize: "x-small" }}>Noise Baby Status</h3>
                <p style={{ fontWeight: "bold" }}>Audiostream not available. Classification not started.</p>
            </section>
        </>;
    }

    function isDetectionEnabled() {
        // return props.noiseDetectionEnabled || (props.babyStatus.noiseBabyStatus >= 0 && props.babyStatus.aiBabyStatus >=0);
        return (props.babyStatus.noiseBabyStatus >= 0 && props.babyStatus.aiBabyStatus >=0);
    }

    function currentStatusFromActivityLog() {
        // console.log(`currentStatusDuration ${props.activityLog}`);
        if (props.activityLog && props.activityLog.length > 0) {
            return props.activityLog[props.activityLog.length - 1];
        }
        return null;
    }

    // function currentStatusDuration(now = null) {
    function currentStatusDuration(now = null) {
        const status = currentStatusFromActivityLog();
        if (status && status.start_time) {
            // if (now === null) now = new Date();
            const now = currentDate;
            const difference = now - new Date(status.start_time);
            const secondsDifference = Math.floor(difference/1000);
            return secondsDifference;
        }
        return null;
    }

    function currentStatusDate() {
        const status = currentStatusFromActivityLog();
        if (status && status.start_time) {
            return new Date(status.start_time).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit', second: '2-digit'});
        }
        return null;
    }

}

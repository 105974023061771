import { IonButton, IonCol, IonGrid, IonIcon, IonLabel, IonTabButton } from "@ionic/react";
import { useState } from "react";

function ToggleIonIconButton2(props) {
    const iconSelected = props.iconSelected;
    const iconUnselected = props.iconUnselected;
    const selected = (props.selected ? props.selected : false);
    const disabled =(props.disabled ? props.disabled : false);

    const currentIcon = (props.selected ? iconSelected : iconUnselected);
    const onChange = props.onChange;

    const handleClick = (event) => {
        if (onChange) {
            onChange(event);
        }
    }
    const buttonStyle = {
        // height: "auto",
        borderRadius: "50%",
        color: "var(--ion-color-primary-contrast, #fff)",
        background: "var(--ion-color-primary, #3880ff)",
        // background: "#2EAFD9",
        boxShadow: "0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12)",
        width: "56px",
        height: "56px",
        marginBottom: "5px",
        // strokeWidth: "var(--ionicon-stroke-width, 25px)"
    }
    const buttonInnerStyle ={
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "10px",
        fontSize: "small",
        // fontWeight: "bold"
        width: "56px"
    }

    return <div style={buttonInnerStyle}>
                <IonButton onClick={handleClick} style={buttonStyle} disabled={disabled} props>
                    <IonIcon icon={currentIcon} size={(props.size ? props.size : "small")}/>
                </IonButton>
                <IonLabel style={{textAlign: "center", height: "20px"}}>{props.children}</IonLabel>
            </div>

}

export default ToggleIonIconButton2;

import {useEffect, useRef, useState} from "react";

function usePeerDeviceStatus(peerRef = null) {
    const [peerDeviceStatus, setPeerDeviceStatus] = useState("offline"); // "offline"
    const [peerDeviceOfflineTimestamp, setPeerDeviceOfflineTimestamp] = useState(null); // "offline"
    //const [lastPing, setLastPing] = useState();
    const lastPing = useRef();
    const timer = useRef();
    const thresholdTime = 10*1000;

    const peerOnlineEventReceived = () => {
        lastPing.current = Date.now();
        setPeerDeviceStatus("online");
    }

    //call this method e.g. if peer.on("close") was called
    const peerOfflineEventReceived = () => {
        setPeerDeviceStatus("offline");
        setPeerDeviceOfflineTimestamp(Date.now());
    }

    const peerConnectingEventReceived = () => {
        setPeerDeviceStatus("connecting");
        setPeerDeviceOfflineTimestamp(Date.now());
    }

    const timerUpdateDeviceStatus = () => {
        // console.log("useChildDevicesStatus: timerUpdateDeviceStatus")
        // if last update older than 3 minutes then set offline
        if (lastPing.current == undefined || (Date.now() - lastPing.current) > thresholdTime ) {
            if (peerRef && peerRef.current && !peerRef.current.destroyed) {
                // console.log('do not mark peer as offline since webrtc connection still active')
                return;
            }
            peerOfflineEventReceived();
        }
    }

    // function processed on mount
    useEffect(() => {
        //setup timer for checking status of child device every n seconds
        timer.current = setInterval(timerUpdateDeviceStatus, thresholdTime);

        return () => {
            console.log('component unmount');
            // remove timer
            clearInterval(timer.current);
        }
    }, []);


    return { peerDeviceOfflineTimestamp, peerDeviceStatus, peerOnlineEventReceived, peerOfflineEventReceived , peerConnectingEventReceived};

}

export default usePeerDeviceStatus;

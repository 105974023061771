//import firebase from 'firebase';
import firebase from 'firebase/app';
import 'firebase/auth';
import { useEffect, useState, useRef, useContext } from "react";
import { NavContext } from "@ionic/react";
import FirebaseSignaling from "../components/FirebaseSignaling";

const uiConfig = {
    callbacks: {
        signInSuccessWithAuthResult: function (authResult, redirectUrl) {
            // User successfully signed in.
            // Return type determines whether we continue the redirect automatically
            // or whether we leave that to developer to handle.
            var user = authResult.user;
            if (authResult.additionalUserInfo.isNewUser) {
                console.log("new signin");
                // user.sendEmailVerification();
                // return false;
            }

            return true;
        },
        uiShown: function () {
            // The widget is rendered.
            // Hide the loader.
            //document.getElementById('loader').style.display = 'none';
        }
    },
    // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
    // signInFlow: 'popup',
    signInSuccessUrl: '/station',
    signInOptions: [
        // Leave the lines as is for the providers you want to offer your users.
        // firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
        // firebase.auth.GithubAuthProvider.PROVIDER_ID,
        {
            provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
            // requireDisplayName: false,
            signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
            emailLinkSignIn: function () {
                return {
                    url: '/login',
                    // dynamicLinkDomain: 'example.page.link',
                }
            }
        }
        // firebase.auth.PhoneAuthProvider.PROVIDER_ID
    ],
    // Terms of service url.
    // tosUrl: '<your-tos-url>',
    // Privacy policy url.
    // privacyPolicyUrl: '<your-privacy-policy-url>'
};

function useAuthRequired(roomId = null) {
    const [loggedIn, setLoggedIn] = useState(true);
    const [firebaseLoggedIn, setFirebaseLoggedIn] = useState(false);
    const [anonymousLogin, setAnonymousLogin] = useState(false);
    const [tokenValidationInProgress, setTokenValidationInProgress] = useState(true);

    const { navigate } = useContext(NavContext);

    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    const isSourcePWA = urlParams.get('source') === "pwa" || urlParams.get('source') === "playstore";//source=pwa or play_store

    useEffect(async () => {
        firebase.auth().onAuthStateChanged(function (user) {
            console.log(`onAuthStateChanged ${user} ${anonymousLogin} ${tokenValidationInProgress}`)
            if (user && !firebaseLoggedIn) {
                setFirebaseLoggedIn(true);
            }

            if (user || anonymousLogin || tokenValidationInProgress) {
                console.log('logged in');
                if (!loggedIn) setLoggedIn(true);
            } else {
                console.log('not logged in');
                if (loggedIn) setLoggedIn(false);
                // navigate('/login/');
            }

        });
    }, []);

    useEffect(async () => {
        await verifyToken();

        if (!loggedIn) {
            // navigate('/login/');
            window.location.href = "/login/";
        }
    }, [loggedIn]);

    function signInAnonymously() {
        console.log('Anonymous login successful')

        firebase.auth().signInAnonymously()
            .then(() => {
                console.log('Anonymous firebase login session created');
            })
            .catch((error) => {
                console.log(`Unable to sign in firebase anonymously`, error);
            });

        setLoggedIn(true);
        setAnonymousLogin(true);
        setTokenValidationInProgress(false);
    }

    async function verifyToken() {
        if (isSourcePWA) {
            signInAnonymously();
        } else if ("getInstalledRelatedApps" in navigator && ((await navigator.getInstalledRelatedApps()).length > 0)) {
            signInAnonymously();
        }
            else
        if (token && roomId) {
            console.log('verify token')
            const tokenVerified = await FirebaseSignaling.singleton.verifyToken(roomId, token)
            if (firebaseLoggedIn) {
                setTokenValidationInProgress(false);
            } else if (tokenVerified) {
                signInAnonymously();
            } else {
                setAnonymousLogin(false);
                setTokenValidationInProgress(false);
            }
        } else {
            if (!firebaseLoggedIn && tokenValidationInProgress) {
                setTokenValidationInProgress(false);
                setAnonymousLogin(false);
                setTokenValidationInProgress(false);
            }
        }

    }
}



/*
  function isTokenSentToServer() {
    return window.localStorage.getItem('sentToServer') === '1';
  }

  function setTokenSentToServer(sent) {
    window.localStorage.setItem('sentToServer', sent ? '1' : '0');
  }
*/
export { useAuthRequired, uiConfig };



import firebase from 'firebase/app';
import 'firebase/messaging';   // for cloud messaging

import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
    IonAlert,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader, IonIcon,
    IonLoading,
    IonModal,
    IonPage,
    IonPopover,
    IonRow,
    IonTitle,
    IonToggle,
    IonToolbar,
    NavContext
} from '@ionic/react';
import {
    alertCircleOutline, checkmarkCircleOutline,
    flashOffOutline,
    flashOutline,
    settingsOutline,
    videocamOffOutline,
    videocamOutline,
    volumeHighOutline,
    volumeMuteOutline
} from 'ionicons/icons';
import ToggleIonIconButton2 from "../components/toggle-ion-icon-button2";

import BabyActivityLog2 from "../components/BabyActivityLog2";

import './Home.css';
import useParentPeer, {
    UM_CONSTRAINTS_AUDIO,
    UM_CONSTRAINTS_DATA,
    UM_CONSTRAINTS_VIDEO_AUDIO
} from '../hooks/useParentPeer';
import { Grid } from '@material-ui/core';
import Settings2 from "../pages/Settings";
import { useSettingsConfig } from '../hooks/useSettings';
import { JsonToTable } from 'react-json-to-table';
import "./ParentStation.css";

import ReactGA from 'react-ga';
import { useAuthRequired } from "../hooks/useAuth";
import useSleepEnable from "../hooks/useSleepEnable.js";
// import registerExceptionHandler from "../components/exceptionHandler";
import { babyStatusGreater, enumBabyStatusImg } from "../helper";
import __ from "../components/Translate";
import {BabyStatus} from "../components/BabyStatus";
import {BabyStatusSection} from "../components/BabyStatusSection";
import {BrowserPermissionStatus} from "../components/BrowserPermissionStatus";

ReactGA.initialize('UA-188532611-1');
ReactGA.pageview(window.location.pathname + window.location.search);

/*
ReactGA.exception({
    description: 'test',
    fatal: false
});
*/


const ParentStation: React.FC = (props): JSX.Element => {

    // registerExceptionHandler();

    const [switchedOn, setSwitchedOn] = useState(false)

    const [showAlertBackgroundNotification, setShowAlertBackgroundNotification] = useState(false)
    const [showAlertEndMonitoring, setShowAlertEndMonitoringShowAlert1] = useState(false);
    const [showSettingsModal, setShowSettingsModal] = useState(false);

    const [roomId] = useState(props.match.params.room);
    useAuthRequired(roomId);

    const [babyVideoEnabled, setBabyVideoEnabled] = useState(false);
    const [babyAudioEnabled, setBabyAudioEnabled] = useState(false);
    const [showLoading, setShowLoading] = useState(true)
    const [cryStreamEnabled, setCryStreamEnabled] = useState(false);
    const userVideo = useRef();
    const partnerVideo = useRef();
    const settingsConfig = useSettingsConfig();
    const {
        developmentMode,
        includeSpeechEnabled: config, setIncludeSpeechEnabled: setConfig,
    } = settingsConfig;
    //const [connected, setConnected] = useState(false)

    const { peerDeviceStatus, babyStatus, classifications, activityLog, createPeerConnection, endCall, webPushStatus, permissions } = useParentPeer(roomId, userVideo, partnerVideo);
    const oldBabyStatus = useRef<any>(0);
    const { noSleepEnable, recreateNoSleep } = useSleepEnable();

    const { navigate } = useContext(NavContext);

    // Call this function when required to redirect with the back animation
    const redirect = useCallback(
        () => navigate('/station', 'back'),
        [navigate]
    );

    async function reconnectPeer(constraints) {
        console.log('reconnectPeer');

        endCall();
        const peer = await createPeerConnection(constraints);

        peer.on('connect', () => {
            console.log("peer.on connect")
        });

        peer.on("close", () => {
            //noSleepEnable();
        });

        return peer
    }

    useEffect(() => {
        console.log('useEffect switchedOn')
        if (switchedOn) {
            recreateNoSleep();
            noSleepEnable();
            reconnectPeer(UM_CONSTRAINTS_DATA);
        } else {
            noSleepEnable(false);
            endCall();
        }
    }, [switchedOn])



    async function handleVisibilityChange() {
        if (document.hidden) {
            setSwitchedOn(false);
        } else {
            recreateNoSleep();
            //setSwitchedOn(true);
        }
    }


    useEffect(() => {
        console.log('useEffect[] ParentStation')
        navigator.mediaDevices.getUserMedia({ audio: true, video: true })
            .then(function (stream) {
                /* use the stream */
            })
            .catch(function (err) {
                /* handle the error */
            });
        noSleepEnable();
        setShowAlertBackgroundNotification(!firebase.messaging.isSupported())
        if (("Notification" in window)) {
            if (Notification.permission !== 'denied') {
                console.log("Request notification permission!")
                Notification.requestPermission()
            }
        }

        document.addEventListener("visibilitychange", handleVisibilityChange, {passive: true});
        //handleVisibilityChange();
        //noSleepEnable();

        //reconnectPeer(UM_CONSTRAINTS_DATA);

    }, [])

    async function handleToggleBabyStreamEnabled(constraints, isEnabled, setEnableFunction, htmlStreamElement) {
        setShowLoading(true);
        const umc = constraints
        const peer = await reconnectPeer(umc)
        if (isEnabled) {
            setEnableFunction(true);
            peer.on("stream", stream => {
                setShowLoading(false);
                noSleepEnable(false);
                if (htmlStreamElement.current) {
                    htmlStreamElement.current.srcObject = stream;
                }
            });
        }
        else {
            noSleepEnable();
            peer.on('connect', () => {
                setEnableFunction(false);
                setShowLoading(false);
            })
        }
    }

    async function handleToggleBabyAudioEnabled() {
        const isEnabled = !babyAudioEnabled;
        const umc = (isEnabled ? UM_CONSTRAINTS_AUDIO : UM_CONSTRAINTS_DATA);
        handleToggleBabyStreamEnabled(umc, isEnabled, setBabyAudioEnabled, partnerVideo);
        ReactGA.event({
            category: 'Parent Station',
            action: 'Main Screen',
            label: `ToggleBabyAudioEnabled - ${!babyAudioEnabled}`,
            nonInteraction: true
        });
    }

    async function handleToggleBabyVideoEnabled() {
        const isEnabled = !babyVideoEnabled;
        const umc = (isEnabled ? UM_CONSTRAINTS_VIDEO_AUDIO : UM_CONSTRAINTS_DATA)
        handleToggleBabyStreamEnabled(umc, isEnabled, setBabyVideoEnabled, partnerVideo);
        ReactGA.event({
            category: 'Parent Station',
            action: 'Main Screen',
            label: `ToggleBabyVideoEnabled - ${!babyVideoEnabled}`,
            nonInteraction: true
        });
    }

    function dismissBabyStream(event) {
        if (babyAudioEnabled) {
            handleToggleBabyAudioEnabled();
        } else
            if (babyVideoEnabled) {
                handleToggleBabyVideoEnabled();
            }
    }

    function playAudio(url) {
        window.AudioContext = window.AudioContext || window.webkitAudioContext; //fix up prefixing
        var context = new AudioContext(); //context
        var source = context.createBufferSource(); //source node
        source.connect(context.destination); //connect source to speakers so we can hear it
        var request = new XMLHttpRequest();
        request.open('GET', url, true);
        request.responseType = 'arraybuffer'; //the  response is an array of bits
        request.onload = function () {
            context.decodeAudioData(request.response, function (response) {
                source.buffer = response;
                source.start(0); //play audio immediately
                //source.loop = true;
            }, function () { console.error('The request failed.'); });
        }
        request.send();
    }


    function showStatusNotification() {
        if (babyStatus.aiBabyStatus > -1) {
            const notification = new Notification("NiceNice.Baby",
                {
                    "body": babyStatus.getAiStatusMessage() + "!",
                    "tag": "childNoise",
                    renotify: true,
                    timestamp: Date.now()
                });
            // if click on notification then focus app and close the notification.
            notification.onclick = function () {
                window.focus();
                this.close();
            }
        }
    }

    useEffect(() => {
        console.log('useEffect audioJingle')

        const audioJingle = {
            "2": "/assets/sounds/baby_crying.mp3",
            "1": "/assets/sounds/baby_awake.mp3"
            // "0": "/assets/sounds/baby_awake.mp3"
        }

        // only play if babystatus greater than older status
        // if (babyStatusGreater(mlBabyStatus, oldBabyStatus.current))
        if ((babyStatus.aiBabyStatus > oldBabyStatus.current.aiBabyStatus) && Object.keys(audioJingle).includes(babyStatus.aiBabyStatus.toString())) {
            playAudio(audioJingle[babyStatus.aiBabyStatus.toString()]);
            // if app not in background
            if (!document.hidden) {
                // if change to crying then stream audio from child devices
                if (babyStatus.aiBabyStatus === 2 && !babyAudioEnabled && !babyVideoEnabled && !cryStreamEnabled) {
                    handleToggleBabyAudioEnabled();
                }
                // if applicaiton is in backgroup show a notification
            } else {
                showStatusNotification();
            }
        } else {
            if (config.alertMode === 'noise') {
                if ((babyStatus.noiseBabyStatus > oldBabyStatus.current.noiseBabyStatus) && Object.keys(audioJingle).includes(babyStatus.noiseBabyStatus.toString())) {
                    playAudio(audioJingle[babyStatus.noiseBabyStatus.toString()])
                }
            }
        }
        oldBabyStatus.current = babyStatus;

    }, [babyStatus]); //noMlBabyStatus,

    return (
        <IonPage>
            {/*
         <div  style={{ backgroundImage:`url("/assets/image/baby/baby_awake_1.svg")`}}>
         </div>
            */}
            <IonAlert
                isOpen={showAlertBackgroundNotification}
                onDidDismiss={() => setShowAlertBackgroundNotification(false)}
                header={'Warning'}
                subHeader={'No background notification'}
                message={'Your browser does not support background notification. If this application goes to background you will not receive notification. Consider to upgrade or switch to a new browser.'}
                buttons={['OK']}
            />
            <IonAlert
                isOpen={!switchedOn}
                onDidDismiss={() => setSwitchedOn(true)}
                header={'Info'}
                subHeader={'Connecting to Baby Station'}
                message={'You will now (re-)connect to your baby station.'}
                buttons={[
                    {
                        text: 'OK',
                        handler: () => {
                            noSleepEnable(true);
                        }
                    }
                ]}
            />


            <IonAlert
                isOpen={showAlertEndMonitoring}
                onDidDismiss={() => setShowAlertEndMonitoringShowAlert1(false)}
                // cssClass='my-custom-class'
                header={'End Monitoring'}
                // subHeader={'Subtitle'}
                message={'Do you really want to end monitoring?'}
                // buttons={['Disagree', 'Agree']}
                buttons={[
                    {
                        text: 'Disagree',
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {

                            console.log('Confirm Cancel');
                        }
                    },
                    {
                        text: 'Agree',
                        handler: () => {
                            console.log('Confirm Ok');
                            redirect();
                        }
                    }
                ]}
            />
            <IonContent style={{
                "--background": "#504E6A",
                "color": "black",
            }}>
                <div style={{ display: "none" }}>
                    <div id="firebaseui-auth-container"></div>
                    <div id="loader">Loading...</div>
                </div>

                <IonGrid>
                    <IonRow class="ion-justify-content-center">
                        {/*<IonCol size="3">ion-col center</IonCol>*/}
                        <IonButton
                            onClick={() => setShowAlertEndMonitoringShowAlert1(true)}
                            // expand="full"
                            // href="/"
                            size="small"
                            shape="round"
                            fill="clear" style={{
                                "--background": "#7D7C90",
                                "--color": "white",
                                "textTransform": "capitalize",
                                "fontWeight": "400",
                                "marginTop": "10px",
                            }}>End Monitoring</IonButton>
                    </IonRow>
                </IonGrid>

                <BrowserPermissionStatus permissions={permissions} />

                <IonCard style={{ marginInline: "10px", marginTop: "0px" }}>
                    {/*
                    <IonCardHeader>
                        <IonCardTitle>Parent Station</IonCardTitle>
                    </IonCardHeader>
*/}

                    <IonCardContent style={{ paddingTop: "5px" }}>
                        {/*<section style={{ marginTop: "10px" }}>*/}
                        {/*
                        <section>
                            <h3 style={{ textTransform: 'uppercase', fontSize: "x-small" }}>
                                Baby Status
                            </h3>
                            <p style={{ fontWeight: "bold" }}>{babyStatus === 'unknown' || babyStatus === '' ? 'Baby Station not connected yet' : __(babyStatus)}</p>
                            "Baby Status: {babyStatus}"
                        </section>
                         */}
{/*
                        <section style={{ marginTop: "10px" }}>
                            <h3 style={{ textTransform: 'uppercase', fontSize: "x-small" }}>Noise Baby Status</h3>
                            <p style={{ fontWeight: "bold" }}>{ babyStatus.noiseBabyStatus >= 0 ? `Baby station detected ` + babyStatus.getNoiseStatusMessage() : "Audiostream not available. Classification not started."}</p>
                        </section>
                        <section style={{ marginTop: "10px" }}>
                            <h3 style={{ textTransform: 'uppercase', fontSize: "x-small" }}>AI Baby Status</h3>
                            <p style={{ fontWeight: "bold" }}>{ babyStatus.aiBabyStatus >=0 ? `` + __(`aiStatus.${babyStatus.aiBabyStatus}`) : "Audiostream not available. Classification not started."}</p>
                        </section>
*/}
                        <BabyStatusSection babyStatus={babyStatus} activityLog={activityLog}/>
                    </IonCardContent>


                    {/*{mlBabyStatus}*/}
                    <img src={enumBabyStatusImg[babyStatus.aiBabyStatus ? babyStatus.aiBabyStatus : "unknown"]} className="center_image" />


                    <IonCardContent>
                        <section style={{ marginBottom: "10px" }}>
                            <h3 style={{ textTransform: 'uppercase', fontSize: "x-small" }}>
                                Connection Status</h3>
                            <p style={{ fontWeight: "bold" }}>
                                <span className="text">Connection to Baby Monitor: {peerDeviceStatus} </span>
                                {peerDeviceStatus === 'online' &&
                                    <IonIcon className="icon" style={{color: "green"}} size="small" icon={checkmarkCircleOutline}/>
                                }
                                {peerDeviceStatus !== 'online' &&
                                    <IonIcon className="icon" style={{color: "orange"}} size="small" icon={alertCircleOutline}/>
                                }
                            </p>
                            <p style={{ fontWeight: "bold" }}>
                                <span className="text">Background Notifications Support: {__(`webpush.status.${webPushStatus}`)} </span>
                                {webPushStatus === 1 &&
                                <IonIcon className="icon" style={{color: "green"}} size="small" icon={checkmarkCircleOutline}/>
                                }
                                {/*{peerDeviceStatus !== 'online' &&*/}
                                {/*<IonIcon className="icon" style={{color: "orange"}} size="small" icon={alertCircleOutline}/>*/}
                                {/*}*/}
                            </p>


                            <p style={{ fontWeight: "bold" }}>Connection Code: {roomId}</p>
                        </section>
                        <section style={{ marginTop: "10px" }}>
                            <h3 style={{ textTransform: 'uppercase', fontSize: "x-small" }}>Activity Log</h3>
                            <p style={{ fontWeight: "bold" }}>
                                <BabyActivityLog2 activityLog={activityLog} />
                            </p>
                        </section>
                    </IonCardContent>
                    <span style={{ fontStyle: "italic", float: "right", marginRight: "5px"}}>Send feedback to <a href="mailto:support@nicenice.baby?subject=NiceNice.Baby Feedback">support@nicenice.baby</a></span>
                </IonCard>

                <Grid container spacing={3}>

                    <Grid container item xs={12} >
                        {developmentMode &&
                            <Grid className="classification" item xs={12} >
                                <JsonToTable json={classifications} />
                            </Grid>
                        }
                        <IonModal isOpen={showSettingsModal} cssClass='my-custom-class'
                            onDidDismiss={() => setShowSettingsModal(false)}>
                            <IonHeader translucent>
                                <IonToolbar>
                                    <IonTitle>Settings</IonTitle>
                                    <IonButtons slot="end">
                                        <IonButton onclick={() => setShowSettingsModal(false)}>Close</IonButton>
                                    </IonButtons>
                                </IonToolbar>
                            </IonHeader>
                            <IonContent fullscreen>
                                {/*<p>This is modal content</p>*/}
                                {/*<IonButton onClick={() => setShowSettingsModal(false)}>Close</IonButton>*/}
                                {/*<SettingsRC />*/}
                                <Settings2 mode="parentstation" />
                            </IonContent>
                        </IonModal>
                        <IonModal isOpen={babyVideoEnabled || babyAudioEnabled}
                            onWillDismiss={dismissBabyStream}
                            cssClass="videoContainer" >
                            <IonHeader translucent>
                                <IonToolbar>
                                    <IonTitle>Baby Stream</IonTitle>
                                    <IonButtons slot="end">
                                        <IonButton onclick={dismissBabyStream}>Close</IonButton>
                                    </IonButtons>
                                </IonToolbar>
                            </IonHeader>
                            <IonContent fullscreen>

                                {/*<p>Position the camera so that baby is in view.</p>*/}
                                {/*<IonButton onClick={() => setShowCamera(false)}>Close</IonButton>*/}
                                <div>
                                    {babyAudioEnabled &&
                                        <img src="/assets/image/mic-outline.svg" alt="Microphone" />
                                    }
                                    {babyAudioEnabled && !showLoading &&
                                        <audio playsInline ref={partnerVideo} autoPlay />
                                    }
                                </div>
                                {babyAudioEnabled && showLoading &&
                                    <IonLoading
                                        isOpen={showLoading}
                                        message={'Please wait...'}
                                        duration={15000}
                                        onDidDismiss={() => {
                                            if (!partnerVideo.current && babyAudioEnabled && peerDeviceStatus != "online") {
                                                setShowLoading(false);
                                                setBabyAudioEnabled(false);
                                                reconnectPeer(UM_CONSTRAINTS_DATA);
                                            }
                                        }}
                                    />
                                }

                                {babyVideoEnabled && !showLoading &&
                                    <video className="userVideo" playsInline ref={partnerVideo} autoPlay />

                                }
                                {babyVideoEnabled && showLoading &&
                                    <IonLoading
                                        isOpen={showLoading}
                                        message={'Please wait...'}
                                        duration={15000}
                                        onDidDismiss={() => {
                                            if (!partnerVideo.current && babyVideoEnabled && peerDeviceStatus != "online") {
                                                setShowLoading(false);
                                                setBabyVideoEnabled(false);
                                                reconnectPeer(UM_CONSTRAINTS_DATA);
                                            }
                                        }}
                                    />
                                }

                            </IonContent>
                        </IonModal>

                    </Grid>
                </Grid>
            </IonContent>
            <IonFooter style={{
                "--background": "#504E6A",
                "color": "white",
                background: "#504E6A",
                backgroundImage: "none !important"
            }}>
                <IonGrid>
                    <IonRow class="ion-justify-content-center" style={{ "--background": "#504E6A", "color": "white" }}>
                        {/*<IonCol size="3">ion-col center</IonCol>*/}
                        <IonButtons>
                            {/*
                            <ToggleIonIconButton2 size="large" iconSelected={flashOffOutline} iconUnselected={flashOutline} selected={cryStreamEnabled}
                                onChange={() => {
                                    setCryStreamEnabled(!cryStreamEnabled);
                                    ReactGA.event({
                                        category: 'Parent Station',
                                        action: 'Main Screen',
                                        label: `Auto-Stream - ${cryStreamEnabled}`,
                                        nonInteraction: true
                                    });
                                }}>
                                Auto-Stream Baby Cry
                            </ToggleIonIconButton2>
*/}
                            <ToggleIonIconButton2 size="large" iconSelected={videocamOutline} iconUnselected={videocamOffOutline} selected={babyVideoEnabled}
                                onChange={handleToggleBabyVideoEnabled}
                                disabled={peerDeviceStatus != "online"}>
                                Baby Video</ToggleIonIconButton2>
                            <ToggleIonIconButton2 size="large" iconSelected={volumeHighOutline} iconUnselected={volumeMuteOutline} selected={babyAudioEnabled}
                                onChange={handleToggleBabyAudioEnabled}
                                disabled={peerDeviceStatus != "online"}>
                                Baby Audio</ToggleIonIconButton2>
                            <ToggleIonIconButton2 size="large" iconSelected={settingsOutline} iconUnselected={settingsOutline} selected={settingsOutline}
                                onChange={() => {
                                    setShowSettingsModal(true);
                                    ReactGA.event({
                                        category: 'Parent Station',
                                        action: 'Main Screen',
                                        label: `Show Settings`,
                                        nonInteraction: true
                                    });
                                }}>
                                Settings</ToggleIonIconButton2>
                        </IonButtons>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default ParentStation;



import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
    IonAlert,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader, IonIcon, IonLabel,
    IonModal,
    IonPage, IonRange,
    IonRow,
    IonTitle,
    IonToast,
    IonToggle,
    IonToolbar,
    NavContext
} from '@ionic/react';
import {
    settingsOutline,
    shareSocialOutline,
    moonOutline,
    cameraOutline,
    alertCircle,
    alertCircleOutline, checkmarkCircleOutline, sunny, cellularOutline, volumeMediumOutline
} from 'ionicons/icons';

import './Home.css';
import { babyStatusGreater, enumBabyStatusImg } from "../helper";

import {Permissions, PermissionType, Plugins} from '@capacitor/core';
import useChildPeer from '../hooks/useWebRtc.js';
import Grid from "@material-ui/core/Grid";
import { JsonToTable } from "react-json-to-table";
import Settings2 from "../pages/Settings";
import { useSettingsConfig } from "../hooks/useSettings";
import { ShareActionSheet } from "../components/ShareActionSheet";
import ToggleIonIconButton2 from "../components/toggle-ion-icon-button2";
import ReactGA from 'react-ga';

import { useAuthRequired } from "../hooks/useAuth";
import useSleepEnable from '../hooks/useSleepEnable';
import { createMeterNode, createMeter } from '../components/audiometer/web-audio-peak-meter';

import __ from '../components/Translate'
import { BabyStatus } from '../components/BabyStatus'

// import registerExceptionHandler from '../components/exceptionHandler'
import FirebaseSignaling from "../FirebaseSignaling";
import { fade } from '@material-ui/core';
import Bugsnag from "@bugsnag/js";
import {getParentUrl} from "../util/utils";
import {BabyStatusSection} from "../components/BabyStatusSection";
import utils from "../components/audiometer/web-audio-peak-meter/utils";
import {BrowserPermissionStatus} from "../components/BrowserPermissionStatus";


ReactGA.initialize('UA-188532611-1');
ReactGA.pageview(window.location.pathname + window.location.search);

const BabyStation: React.FC = () => {

    // registerExceptionHandler();

    const { Toast } = Plugins;

    useAuthRequired();

    const [showAlert1, setShowAlert1] = useState(false);
    const { navigate } = useContext(NavContext);

    // Call this function when required to redirect with the back animation
    const redirect = useCallback(
        () => navigate('/station/', 'back'),
        [navigate]
    );

    const showSnackBarMessage = (msg: string) => {
        console.log('showSnackBarMessage');

        Toast.show({
            text: msg
        });

        setSnackBarMessage(msg);
        setSnackbarOpen(true);
    }
    const userVideo = useRef();
    const partnerVideo = useRef();

    const settingsConfig = useSettingsConfig();
    const {
        developmentMode, noiseDetectionEnabled
    } = settingsConfig;

    const DEFAULT_NOISE_DB_THRESHOLD = -40;
    const [noiseAlertThresholdDbSlider, setNoiseAlertThresholdDbSlider] = useState(DEFAULT_NOISE_DB_THRESHOLD);
    const meterData = useRef<any>();
    const updateMeter = useRef<boolean>(false);
    const { roomId, shareToken, suspendStream, resumeStream, activityLog, connectionStatus, babyStatus, showUserVideo, subscribeRoom, clearRoom, getPushToken, permissions, messagingService } = useChildPeer(showSnackBarMessage, null, userVideo, partnerVideo, settingsConfig, startMeter, meterData);

    const [snackBarMessage, setSnackBarMessage] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const [showSettingsModal, setShowSettingsModal] = useState(false);
    const [fadeOut, setFadeOut] = useState<Boolean>();
    const dimmerTimeOut = useRef<NodeJS.Timeout>();
    //const [showShareCodeModal, setShowShareCodeModal] = useState(false);
    const [showCamera, setShowCamera] = useState(false);
    const [showActionSheet, setShowActionSheet] = useState(false);
    const { noSleepEnable, recreateNoSleep } = useSleepEnable();

    const [showAlertNoBackgroundOperation, setShowAlertNoBackgroundOperation] = useState(false);
    /*
    const [switchedOn, setSwitchedOn] = useState(false)
    useEffect(() => {
        if (switchedOn) {
            noSleepEnable();
            //subscribeRoom();

        } else {
            noSleepEnable(false);
            //clearRoom();
        }
    }, [switchedOn])
    */

    function showCopiedMessage(msg: string) {
        navigator.clipboard.writeText(msg);
        showSnackBarMessage('Code copied to clipboard');
    }

    function mobileCheck() {
        var hasTouchScreen = false;
        if ("maxTouchPoints" in navigator) {
            hasTouchScreen = navigator.maxTouchPoints > 0;
        } else if ("msMaxTouchPoints" in navigator) {
            hasTouchScreen = navigator.msMaxTouchPoints > 0;
        } else {
            var mQ = window.matchMedia && matchMedia("(pointer:coarse)");
            if (mQ && mQ.media === "(pointer:coarse)") {
                hasTouchScreen = !!mQ.matches;
            } else if ('orientation' in window) {
                hasTouchScreen = true; // deprecated, but good fallback
            } else {
                // Only as a last resort, fall back to user agent sniffing
                var UA = navigator.userAgent;
                hasTouchScreen = (
                    /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
                    /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA)
                );
            }
        }
        return hasTouchScreen
    }

    async function handleVisibilityChange() {
        if (document.hidden) {
            noSleepEnable(false);
            //setSwitchedOn(false);
        } else {
            recreateNoSleep();
            if (mobileCheck())
                setShowAlertNoBackgroundOperation(true);
        }
    }
    function handleTouchStart() {
        setFadeOut(false)
    }

    useEffect(() => {
        if (fadeOut) {
            showSnackBarMessage("Dimming screen")
            document.body.classList.add('fade-out')
            document.getElementById("fadeoutoverlay").style.display="block";
            /*
            document.addEventListener("touchstart", handleTouchStart, false);
            document.addEventListener("mousemove", handleTouchStart, false);
            document.addEventListener("keypress", handleTouchStart, false);
            */
            clearTimeout(dimmerTimeOut.current)
        }
        else {
            document.body.classList.remove('fade-out')
            /*
            document.removeEventListener("touchstart", handleTouchStart);
            document.removeEventListener("mousemove", handleTouchStart);
            document.removeEventListener("keypress", handleTouchStart);
            */
            document.getElementById("fadeoutoverlay").style.display="none";
            // dimmerTimeOut.current = setTimeout( () => {setFadeOut(true)}, 3*60000);
        }
    }, [fadeOut])


    useEffect(() => {
        // on start up activate nosleep
        //navigator.mediaDevices.getUserMedia({audio: true,video:true }).then( () =>noSleepEnable());
        if (mobileCheck())
            setShowAlertNoBackgroundOperation(true);
        document.addEventListener("visibilitychange", handleVisibilityChange, {passive: true});
        setFadeOut(false)
        document.getElementById("fadeoutoverlay").addEventListener("touchmove", handleTouchStart, {passive: true});
        document.getElementById("fadeoutoverlay").addEventListener("mousemove", handleTouchStart, {passive: true});
        document.getElementById("fadeoutoverlay").addEventListener("keypress", handleTouchStart, {passive: true});
    }, [])

    useEffect(() => {
        if (showCamera) {
            //noSleepEnable();
            showUserVideo();
        }
    }, [showCamera]);


    function openActionSheet() {
        setShowActionSheet(true);
        ReactGA.event({
            category: 'Baby Station',
            action: 'Share Sheet',
            label: 'Open',
            nonInteraction: true
        });
    }

    useEffect(() => {
        return () => {
            console.log('update slider state before rerender')
            // setNoiseAlertThresholdDbSlider(document.querySelector('ion-range').shadowRoot.querySelector('div[role="slider"]').getAttribute('aria-valuenow'));
        }
    }, []);

/*
    useEffect(() => {
        const timer = setTimeout(() => {
            console.log('update slider state via timer')
            try {
            setNoiseAlertThresholdDbSlider(document.querySelector('ion-range').shadowRoot.querySelector('div[role="slider"]').getAttribute('aria-valuenow'));
            } catch (e) {}
        }, 1000);
        return () => clearTimeout(timer);
    }, []);
*/

    // mediaStream: MediaStream
    function startMeter(mediaStream = null) {
        console.log('start meter');
        // var webAudioPeakMeter = require('web-audio-peak-meter');
        var myMeterElement = document.getElementById('my-peak-meter');
        removeAllChildNodes(myMeterElement);
        // var myAudio = document.getElementById('my-audio');
        var myAudio = null;
        var audioCtx = new (window.AudioContext || window.webkitAudioContext)();
        var sourceNode = null;
        if (mediaStream == null && myAudio != null) {
            return;
            sourceNode = audioCtx.createMediaElementSource(myAudio);
        } else if (mediaStream != null) {
            sourceNode = audioCtx.createMediaStreamSource(mediaStream);
        } else {
            console.log('no audio media stream given');
            return;
        }
        // sourceNode.connect(audioCtx.destination);
        const noiseAlertThresholdDb = noiseAlertThresholdDbSlider;
        const meterNode = createMeterNode(sourceNode, audioCtx);
        meterData.current = createMeter(myMeterElement, meterNode, { noiseAlertThresholdDb });
/*
        myAudio.addEventListener('play', function () {
            audioCtx.resume();
        });
*/
    }
    function removeAllChildNodes(parent) {
        while (parent.firstChild) {
            parent.removeChild(parent.firstChild);
        }
    }

    function convertSliderValueToDb() {
        const db = utils.dbFromFloat(noiseAlertThresholdDbSlider == 0 ? 0.1 : (noiseAlertThresholdDbSlider / 100));
        // console.log(`convertSliderValueToDb ${noiseAlertThresholdDbSlider} ${noiseAlertThresholdDbSlider / 100} db ${db}`);
        return db;
    }

    function convertSliderValueToFloat() {
        // const db = utils.dbFromFloat(noiseAlertThresholdDbSlider / 100);
        // console.log(`convertSliderValueToDb ${noiseAlertThresholdDbSlider} ${noiseAlertThresholdDbSlider / 100} db ${db}`);
        return noiseAlertThresholdDbSlider / 100;
    }

    function updateNoiseThresholdInDb(threshold) {
        console.log('updateNoiseThresholdInDb');
        // if (updateMeter.current) {
        //     updateMeter.current = false;
            setNoiseAlertThresholdDbSlider(threshold);
        // }
        if (meterData.current && meterData.current.config) {
            // meterData.current.config.noiseAlertThresholdDb = convertSliderValueToDb();
            // meterData.current.config.noiseAlertThresholdDb = convertSliderValueToFloat();
            meterData.current.config.noiseAlertThresholdDb = threshold;
        }
    }

    return (
        <IonPage>
            <div id="fadeoutoverlay" onClick={() => handleTouchStart()}></div>
            <IonAlert
                isOpen={showAlert1}
                onDidDismiss={() => setShowAlert1(false)}
                // cssClass='my-custom-class'
                header={'End Monitoring'}
                // subHeader={'Subtitle'}
                message={'Do you really want to end monitoring?'}
                // buttons={['Disagree', 'Agree']}
                buttons={[
                    {
                        text: 'Disagree',
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            console.log('Confirm Cancel');
                        }
                    },
                    {
                        text: 'Agree',
                        handler: () => {
                            console.log('Confirm Ok');
                            redirect();
                        }
                    }
                ]}
            />
            <IonAlert
                isOpen={showAlertNoBackgroundOperation}
                onDidDismiss={() => setShowAlertNoBackgroundOperation(false)}
                // cssClass='my-custom-class'
                header={'Background operation'}
                // subHeader={'Subtitle'}
                message={'Please be aware that background observation is not supported in this browser. If you push the application to background ' +
                    'baby observation will be stopped by the operating system. Use Chrome instead for full background operation support.'}
                // buttons={['Disagree', 'Agree']}
                buttons={[
                    {
                        text: 'OK',
                        handler: () => {
                            noSleepEnable(true);
                        }
                    }
                ]}
            />
            <IonContent style={{
                "--background": "#504E6A",
                "color": "black",
            }}>
                <div style={{ display: "none" }}>
                    <div id="firebaseui-auth-container"></div>
                    <div id="loader">Loading...</div>
                </div>

                <IonGrid>
                    <IonRow class="ion-justify-content-center">
                        {/*<IonCol size="3">ion-col center</IonCol>*/}
                        <IonButton
                            onClick={() => setShowAlert1(true)}
                            // expand="full"
                            // href="/"
                            size="small"
                            shape="round"
                            fill="clear" style={{
                                "--background": "#7D7C90",
                                "--color": "white",
                                "textTransform": "capitalize",
                                "fontWeight": "400",
                                "marginTop": "10px",
                            }}>End Monitoring</IonButton>
                    </IonRow>
                </IonGrid>

                <IonRow class="ion-justify-content-center">

                    <IonCard style={{ maxWidth: "900px", marginInline: "10px", marginTop: "0px" }}>
{/*
                        <IonCardHeader>
                            <IonCardTitle>Baby Station</IonCardTitle>
                             // <IonToggle checked={switchedOn} onIonChange={e => setSwitchedOn(e.detail.checked)} />
                        </IonCardHeader>
*/}

                        {/*<img src="../assets/image/babystation2.jpg" />*/}
                        {/*<img src={enumBabyStatusImg[babyStatus]} className="center_image" style={{height: "100px"}} />*/}

                        <IonCardContent style={{margin: '0px', paddingTop: "5px"}}>
                            <section style={{ marginBottom: "10px" }}>
                                <h3 style={{ textTransform: 'uppercase', fontSize: "x-small" }}>
                                    Connection Status</h3>

                                <BrowserPermissionStatus permissions={permissions} />

                                {!connectionStatus &&
                                <p style={{fontWeight: "bold"}}>
                                  <span className="text">{connectionStatus ? ' Parent is connected' : ' Waiting for parent connection'}</span>
                                  <IonIcon className="icon" style={{color: "orange"}} size="small" icon={alertCircleOutline}></IonIcon>
                                </p>
                                }
                                {connectionStatus &&
                                <p style={{fontWeight: "bold"}}>
                                  <span className="text">{connectionStatus ? ' Parent is connected' : ' Waiting for parent connection'}</span>
                                  <IonIcon className="icon" style={{color: "green"}} size="small" icon={checkmarkCircleOutline}></IonIcon>
                                </p>
                                }

                                {getPushToken() &&
                                <p style={{fontWeight: "bold"}}>
                                  <span className="text">Parent background notification enabled</span>
                                  <IonIcon className="icon" style={{color: "green"}} size="small" icon={checkmarkCircleOutline}></IonIcon>
                                </p>
                                }

                                {!connectionStatus &&
                                <div style={{
                                    fontWeight: "bold", fontSize: "large",
                                    boxShadow: "1px 1px lightgrey",
                                    borderStyle: "solid", borderWidth: "1px",
                                    // backgroundColor: "lightgray",
                                    backgroundColor: "#f2fbfd",
                                    borderColor: "lightgray",
                                    // backgroundColor: "gainsboro",
                                    padding: "5px", borderRadius: "5px", color: "black"
                                }}>
                                  <p style={{fontWeight: "bold"}}>Open this app on another device, enter following code
                                    to connect to this device and start monitoring.</p>
                                  <span
                                      // class="text"
                                    style={{
                                        fontWeight: "bold", hyphens: "auto",
                                        // fontSize: "larger",
                                        // marginRight: "2px",  marginLeft: "2px",
                                        padding: "0 2 0 2",
                                        // backgroundColor: "greenyellow"
                                        // backgroundColor: "mediumspringgreen"
                                        // backgroundColor: "yellow"
                                        // backgroundColor: "whitesmoke"
                                        // backgroundColor: "gainsboro"
                                    }}>
                                        Connection Code: <span onClick={() => {
                                        showCopiedMessage(roomId);
                                        ReactGA.event({
                                            category: 'Baby Station',
                                            action: 'Main Screen',
                                            label: 'Copy Connection Code',
                                            nonInteraction: true
                                        });
                                    }}
                                    className="mark"
                                    style={{textDecoration: 'underline', textDecorationStyle: 'dotted'}}
                                  > {roomId === "" ? "..loading.." : roomId} </span>
                                    </span>

                                  <p>
                                    <IonButton
                                      size="small"
                                      shape="round"
                                      onClick={() => {
                                          // const parentUrl = getParentUrl(roomId, false, shareToken)
                                          // showCopiedMessage(parentUrl);
                                          openActionSheet();
                                      }}
                                      color="primary">Share Connection</IonButton>
                                  </p>
                                    {/*
                                    <p>
                                        The share button at the bottom offer additional tools for sharing access to the baby station.
                                    </p>
*/}
                                </div>
                                }
                                {connectionStatus &&
                                <span
                                    // class="text"
                                  style={{
                                      fontWeight: "bold", hyphens: "auto",
                                      // fontSize: "larger",
                                      // marginRight: "2px",  marginLeft: "2px",
                                      // padding: "0 2 0 2",
                                      // backgroundColor: "greenyellow"
                                      // backgroundColor: "mediumspringgreen"
                                      // backgroundColor: "yellow"
                                      // backgroundColor: "whitesmoke"
                                      // backgroundColor: "gainsboro"
                                  }}>Connection Code: <span onClick={() => {
                                    showCopiedMessage(roomId);
                                    ReactGA.event({
                                        category: 'Baby Station',
                                        action: 'Main Screen',
                                        label: 'Copy Connection Code',
                                        nonInteraction: true
                                    });
                                }}
                                                               className="mark"
                                                               style={{textDecoration: 'underline', textDecorationStyle: 'dotted'}}
                                > {roomId === "" ? "..loading.." : roomId} </span>
                                    </span>
                                }

                            </section>
                            <BabyStatusSection noiseDetectionEnabled={noiseDetectionEnabled} babyStatus={babyStatus} />
                            <section style={{ marginTop: "10px" }}>
                                <h3 style={{ textTransform: 'uppercase', fontSize: "x-small" }}>Mic Volume Meter (db)</h3>
                                <div id="my-peak-meter" style={{width: "20em",
                                    // height: "5em",
                                    // margin: "1em 0"
                                }}></div>

{/*
                                {meterData.current && meterData.current.tempPeaks[0]}
                                <br/>
                                {meterData.current && utils.dbFromFloat(meterData.current.tempPeaks[0])}
                                <br/>
                                {noiseAlertThresholdDbSlider} / {convertSliderValueToDb()} / {convertSliderValueToFloat()}
*/}
                                <p style={{fontSize: "smaller", margin: "0px", padding: "0px", fontStyle: "italic"}}>Volume levels above the yellow line alerts trigger noise detection. Adjust this threshold below.</p>
                                <h3 style={{ textTransform: 'uppercase', fontSize: "x-small", marginTop: "5px" }}>Mic Volume Threshold for Alerts
                                    {/*<IonIcon style={{fontSize: "15px"}} slot="start" icon={volumeMediumOutline} />*/}
                                </h3>

                                {/*{meterData.current.config.noiseAlertThresholdDb} | {noiseAlertThresholdDbSlider}*/}
                                <IonRange
                                    // style={{ width: "280px", margin: "0px", padding: "0px" }}
                                    // style={{ width: "249px", margin: "0px", padding: "0px", paddingLeft: "10px" }}
                                    style={{ width: "285px", margin: "0px", padding: "0px", paddingLeft: "10px" }}
                                    // min={0} max={100}
                                    min={-49} max={0}
                                    // color="secondary"
                                    color="warning"
                                    value={noiseAlertThresholdDbSlider}
                                    onIonChange={e => {
                                        updateNoiseThresholdInDb(e.detail.value as number);
                                    }}
                                    onIonMouseUp={e => {
                                        // console.log('mouse up');
                                        // updateMeter.current = true;
                                        // setNoiseAlertThresholdDbSlider(document.querySelector('ion-range').shadowRoot.querySelector('div[role="slider"]').getAttribute('aria-valuenow'));
                                        // setNoiseAlertThresholdDbSlider(30);
                                    }}
                                    onMouseUp={e => {
                                        // console.log('mouse up');
                                        // updateMeter.current = true;
                                        // setNoiseAlertThresholdDbSlider(document.querySelector('ion-range').shadowRoot.querySelector('div[role="slider"]').getAttribute('aria-valuenow'));
                                        // setNoiseAlertThresholdDbSlider(31);
                                    }}
/*
                                    onIonBlur={e => {
                                        console.log('onBlur: ', e);
                                        // setNoiseAlertThresholdDbSlider(e.detail.value as number);
                                        // setNoiseAlertThresholdDbSlider(meterData.current.config.noiseAlertThresholdDb);
                                        setNoiseAlertThresholdDbSlider(document.querySelector('ion-range').shadowRoot.querySelector('div[role="slider"]').getAttribute('aria-valuenow'));
                                    }}
*/
                                >
                                    {/*<IonIcon size="small" slot="start" icon={cellularOutline} />*/}
                                    {/*<IonIcon size="small" slot="start" icon={volumeMediumOutline} />*/}
                                    {/*<IonIcon size="small" slot="start" icon={sunny} />*/}
                                    {/*<IonIcon slot="end" icon={sunny} />*/}
                                    <IonIcon size="small" slot="end" icon={volumeMediumOutline} />
                                    {/*<IonLabel slot="start">NoiseAlertThreshold</IonLabel>*/}
                                    {/*<IonLabel slot="end">{noiseAlertThresholdDbSlider} / {convertSliderValueToDb()} / {convertSliderValueToFloat()}</IonLabel>*/}
                                </IonRange>
                                {/*<a style={{paddingLeft: "10px", fontSize: "small"}} onClick={() => updateNoiseThresholdInDb(DEFAULT_NOISE_DB_THRESHOLD)}>Set to default threshold</a>*/}
                                {/*                                <input
                                    id="meter_slider"
                                    type="range"
                                    // onInput="effect('meter_bar', 'meter_slider', 'meter_output')"
                                    // onInput={effect('meter_bar', 'meter_slider', 'meter_output')}
                                    min="0"
                                    max="100"
                                    value="75" />
                                <meter
                                    id="meter_bar"
                                    value="75"
                                    min="0"
                                    max="100"
                                    low="25"
                                    high="75"
                                    optimum="100">
                                </meter>

                                <output
                                    id="meter_output"
                                    htmlFor="meter_slider">75
                                </output>*/}
                                {/*
                                <audio id="my-audio" preload="metadata" controls="controls">
                                    <source src="/assets/sounds/baby_crying.mp3" type="audio/mpeg" />
                                </audio>
                                */}
                            </section>

                            {/*
                            <section style={{ marginTop: "10px" }}>
                                <h3 style={{ textTransform: 'uppercase', fontSize: "x-small" }}>
                                    Camera
                                </h3>
                                <a style={{ textDecoration: 'underline' }} onClick={() => setShowCamera(true)}>
                                    <p style={{ fontWeight: "bold" }}>Enable Camera</p>
                                </a>
                            </section>
                            */}
                        </IonCardContent>
                        <span style={{ fontStyle: "italic", float: "right", marginRight: "5px"}}>Send feedback to <a href="mailto:support@nicenice.baby?subject=NiceNice.Baby Feedback">support@nicenice.baby</a></span>
                    </IonCard>
                </IonRow>

                <Grid container spacing={3}>
                    {/*developmentMode &&
                        <Grid className="classification" item xs={12} >
                            <JsonToTable json={classifications} />
                        </Grid>
                    */}
                    <Grid container item xs={12} >
                        <IonModal isOpen={showSettingsModal} cssClass='my-custom-class'
                            onDidDismiss={() => setShowSettingsModal(false)}>
                            <IonHeader translucent>
                                <IonToolbar>
                                    <IonTitle>Settings</IonTitle>
                                    <IonButtons slot="end">
                                        <IonButton onclick={() => setShowSettingsModal(false)}>Close</IonButton>
                                    </IonButtons>
                                </IonToolbar>
                            </IonHeader>
                            <IonContent fullscreen>
                                <Settings2 messagingService={messagingService} roomId={roomId} mode="babystation"/>
                            </IonContent>
                        </IonModal>
                        <IonModal>

                        </IonModal>
                        <IonModal isOpen={showCamera} cssClass='my-custom-class'
                            onDidDismiss={() => setShowCamera(false)}>
                            <IonHeader translucent>
                                <IonToolbar>
                                    <IonTitle>Camera</IonTitle>
                                    <IonButtons slot="end">
                                        <IonButton onclick={() => setShowCamera(false)}>Close</IonButton>
                                    </IonButtons>
                                </IonToolbar>
                            </IonHeader>
                            <IonContent fullscreen>
                                <Grid item xs={12} style={{ textAlign: "center" }} >
                                    <video playsInline ref={userVideo} autoPlay muted={true} />
                                </Grid>
                            </IonContent>
                        </IonModal>
                    </Grid>
{/*                    <Grid container item xs={12} >
                                                <Dialog
                            open={QRDialogOpen}
                            onClose={handleCloseQRDialog}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description">
                            <DialogTitle id="alert-dialog-title">{"Scan link with parent device."}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    <Qrcode qrtext={getParentUrl(roomId)} />
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <IonButton color="primary" onClick={handleCloseQRDialog}>Ok</IonButton>
                            </DialogActions>
                        </Dialog>
                    </Grid>*/}
{/*
                    <Grid item xs={12} style={{ textAlign: "center" }} >
                        <video className="userVideo" playsInline muted ref={userVideo} autoPlay />
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: "center" }}>
                        <video className="partnerVideo" playsInline ref={partnerVideo} autoPlay />
                    </Grid>
*/}
                </Grid>

                <IonToast
                    isOpen={snackbarOpen}
                    onDidDismiss={() => setSnackbarOpen(false)}
                    message={snackBarMessage}
                    position="top"
                    duration={2000}
                />

                <ShareActionSheet showCopiedMessage={showCopiedMessage}
                                  showActionSheet={showActionSheet}
                                  setShowActionSheet={setShowActionSheet}
                                  roomId={roomId} shareToken={shareToken} />

            </IonContent>
            <IonFooter style={{
                "--background": "#504E6A",
                "color": "white",
                backgroundImage: "none !important",
                background: "#504E6A"
            }}>
                <IonGrid>
                    <IonRow class="ion-justify-content-center" style={{ "--background": "#504E6A", "color": "white", margin: '0px' }}>
                        {/*<IonCol size="3">ion-col center</IonCol>*/}
                        <IonButtons>
                            <ToggleIonIconButton2 size="large" iconSelected={shareSocialOutline} iconUnselected={shareSocialOutline} selected={shareSocialOutline}
                                onChange={() => {
                                    openActionSheet();
                                }}>
                                Share</ToggleIonIconButton2>
                        </IonButtons>
                        <IonButtons>
                            <ToggleIonIconButton2 size="large" iconSelected={cameraOutline} iconUnselected={cameraOutline} selected={cameraOutline}
                                onChange={() => {
                                    setShowCamera(true);
                                    ReactGA.event({
                                        category: 'Baby Station',
                                        action: 'Main Screen',
                                        label: 'Adjust Camera',
                                        nonInteraction: true
                                    });
                                }}>
                                Adjust Camera</ToggleIonIconButton2>
                        </IonButtons>
                        <IonButtons>
                            <ToggleIonIconButton2 size="large" iconSelected={moonOutline} iconUnselected={moonOutline} selected={moonOutline}
                                onChange={() => {
                                    setFadeOut(true);
                                    ReactGA.event({
                                        category: 'Baby Station',
                                        action: 'Main Screen',
                                        label: 'Dim Screen',
                                        nonInteraction: true
                                    });
                                }}>
                                Dim Screen</ToggleIonIconButton2>
                        </IonButtons>
                        <IonButtons>
                            <ToggleIonIconButton2 size="large" iconSelected={settingsOutline} iconUnselected={settingsOutline} selected={settingsOutline}
                                                  onChange={() => {
                                                      setShowSettingsModal(true);
                                                      ReactGA.event({
                                                          category: 'Baby Station',
                                                          action: 'Main Screen',
                                                          label: 'Show Settings',
                                                          nonInteraction: true
                                                      });
                                                  }}>
                                Settings</ToggleIonIconButton2>
                        </IonButtons>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default BabyStation;

import React, {useContext, useEffect, useState} from 'react';
import {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonButton,
    IonButtons,
    IonRow, NavContext
} from '@ionic/react';

import { useAuthState } from 'react-firebase-hooks/auth';

import './Home.css';
import ReactGA from 'react-ga';
import { useAuthRequired, uiConfig } from "../hooks/useAuth";

import useSleepEnable from '../hooks/useSleepEnable';
import * as firebaseui from "firebaseui";
import InstallPWA from '../components/InstallPWA';
// import registerExceptionHandler from "../components/exceptionHandler";

ReactGA.initialize('UA-188532611-1');
ReactGA.pageview(window.location.pathname + window.location.search);

const Home: React.FC = () => {
    //const {noSleepEnable} = useSleepEnable();

    // registerExceptionHandler();
    useAuthRequired();

    // This can also be done via:

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="end">
                        <InstallPWA/>
                        <IonButton>
                            {/*<IonIcon slot="icon-only" icon={ellipsisHorizontal} name="ellipsisHorizontal" ios="ellipsis-horizontal" md="ellipsis-vertical"></IonIcon>*/}
                        </IonButton>
                    </IonButtons>
                    {/*<IonButton slot="end">*/}
                    {/*    <IonMenuButton color="light" auto-hide="false"></IonMenuButton>*/}
                    {/*</IonButton>*/}
                    <IonRow justify-content-center align-items-center >
                    <img src="../assets/image/logo/logo1.svg"
                    style={{height: "30px", marginLeft: "15px"}}/>
                    <IonTitle>
                        NiceNice.Baby
                    </IonTitle>
                    {/*
                    <span className="slogan">Giving parents a break</span>
                    */}


                    </IonRow>
                    {/*<IonButton><IonIcon slot="end" icon={star} /></IonButton>*/}
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <div style={{display: "none"}}>
                    <div id="firebaseui-auth-container"></div>
                    <div id="loader">Loading...</div>
                </div>
                <IonRow class="ion-justify-content-center">
                <IonCard style={{margin: "5px 16px"}}>
                    <img src="../assets/image/babystation2.jpg" />
{/*
                    <IonCardHeader>
                        <IonCardTitle>Baby Station</IonCardTitle>
                    </IonCardHeader>
*/}
                    <IonCardContent style={{paddingTop: "5px", paddingBottom: "5px"}}>
                        I want to use this device as a baby monitor.
                        {/*<IonItem>*/}
                            {/*<IonIcon icon={pin} slot="start" />*/}
                            {/*<IonLabel>I want to use this device as a Baby station.</IonLabel>*/}
                            {/*<IonButton fill="outline" expand="block" href="/station/baby" >Activate Babyphone</IonButton>*/}
                            {/*<IonButton fill="outline" expand="block" href="/child" >Activate Babyphone (Old)</IonButton>*/}
                            <IonButton  fill="outline" expand="block" href="/station/baby" >Activate Babymonitor</IonButton>
                        {/*</IonItem>*/}
                    </IonCardContent>
                </IonCard>
                <IonCard style={{margin: "5px 16px"}}>

                    <img src="../assets/image/parentunit.jpg" />
{/*
                    <IonCardHeader>
                        <IonCardTitle>Parent Unit</IonCardTitle>
                    </IonCardHeader>
*/}
                    <IonCardContent style={{paddingTop: "0px", paddingBottom: "5px"}}>
                        I want to connect to the baby monitor and receive alerts when the baby cries.
                        {/*<IonItem>*/}
                            {/*<IonIcon icon={pin} slot="start" />*/}
                            {/*<IonLabel>I want to use this device as a Baby station.</IonLabel>*/}
                            {/*<IonButton fill="outline" slot="end">Activate Parent Unit</IonButton>*/}
                            {/*<IonButton fill="outline" expand="block" href="/station/parentConnect">Activate Parent Unit</IonButton>*/}
                            {/*<IonButton fill="outline" expand="block" href="/parent">Activate Parent Unit (Old)</IonButton>*/}
                            <IonButton fill="outline" expand="block" href="/station/parentConnect">Activate Parent Unit</IonButton>

                        {/*</IonItem>*/}
                    </IonCardContent>
                </IonCard>
                </IonRow>
{/*
                <IonCard>
                    <IonCardHeader>
                        <IonCardSubtitle>Card Subtitle</IonCardSubtitle>
                        <IonCardTitle>Card Title</IonCardTitle>
                    </IonCardHeader>

                    <IonCardContent>
                        Keep close to Nature's heart... and break clear away, once in awhile,
                        and climb a mountain or spend a week in the woods. Wash your spirit clean.
                    </IonCardContent>
                </IonCard>
*/}

{/*                <IonCard>
                    <IonItem>
                        <IonIcon icon={pin} slot="start" />
                        <IonLabel>ion-item in a card, icon left, button right</IonLabel>
                        <IonButton fill="outline" slot="end">View</IonButton>
                    </IonItem>

                    <IonCardContent>
                        This is content, without any paragraph or header tags,
                        within an ion-cardContent element.
                    </IonCardContent>
                </IonCard>

                <IonCard>
                    <IonItem href="#" className="ion-activated">
                        <IonIcon icon={wifi} slot="start" />
                        <IonLabel>Card Link Item 1 activated</IonLabel>
                    </IonItem>

                    <IonItem href="#">
                        <IonIcon icon={wine} slot="start" />
                        <IonLabel>Card Link Item 2</IonLabel>
                    </IonItem>

                    <IonItem className="ion-activated">
                        <IonIcon icon={warning} slot="start" />
                        <IonLabel>Card Button Item 1 activated</IonLabel>
                    </IonItem>

                    <IonItem>
                        <IonIcon icon={walk} slot="start" />
                        <IonLabel>Card Button Item 2</IonLabel>
                    </IonItem>
                </IonCard>*/}
            </IonContent>
        </IonPage>
    );
/*  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Tab 1</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Tab 1</IonTitle>
          </IonToolbar>
        </IonHeader>
        <ExploreContainer name="Tab 1 page" />
      </IonContent>
    </IonPage>
  );*/
};

export default Home;

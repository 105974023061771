import QRCode from 'qrcode'
import React from "react";

export class Qrcode extends React.Component {

    componentDidMount() {
     let str = this.props.qrtext
        QRCode.toCanvas(document.getElementById('canvas'), str,                  function(error) {
              if (error) console.error(error)
            //console.log('success!')
     })
}

render() {
return (
<div align="center">
     <canvas id="canvas" />
</div>
)}}

export default Qrcode